import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from "../api.service";
import {DataService} from "../data-service.service";
import {MatDialogConfig} from "@angular/material/dialog";
import {SendNotificationComponent} from "../send-notification/send-notification.component";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmPriceChangeDialogComponent} from "../confirm-price-change-dialog/confirm-price-change-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-prices',
    templateUrl: './prices.component.html',
    styleUrls: ['./prices.component.css']
})
export class PricesComponent implements OnInit, OnDestroy {
    products: any[] = [];
    deliveries: any = [];
    noChangeProducts: any;
    noChangeDeliveries: any;
    productPriceChange = false;
    deliveryPriceChange = false;
    isDiscount = false;
    countrySubscription: any;
    changeMade = false;
    deliveryRows = [
        {id: 1, price: "", currency: ""},
        {id: 2, price: "", currency: ""}
    ];

    constructor(private apiService: ApiService, private dataService: DataService, private dialog: MatDialog, private snackBar: MatSnackBar, private translate: TranslateService) {
    }

    ngOnInit() {
        this.getProducts();
        this.getDeliveries();
        this.countrySubscription = this.dataService.country.subscribe((country) => {
            this.getProducts();
            this.getDeliveries();
        });
    }

    getProducts() {
        this.apiService.getPanelProducts().subscribe(
            (data: any[]) => {
                this.products = data['products'];
                this.isDiscount = data['isDiscount'];
                this.noChangeProducts = JSON.stringify(this.products);
            }
        )
    }

    getDeliveries() {
        this.apiService.getPanelDeliveries().subscribe(
            (data: any) => {
                this.deliveries = data.deliveries;
                this.noChangeDeliveries = JSON.stringify(this.deliveries);
            }
        )
    }

    parseArray(stringArray) {
      if (stringArray) {
        return JSON.parse(stringArray);
      } else {
        return [];
      }
    }

    changeProductPriceMode(mode) {
        this.productPriceChange = mode;

        if (mode) {
            this.deliveryPriceChange = false;
        }
    }

    changeDeliveryPriceMode(mode) {
        this.deliveryPriceChange = mode;

        if (mode) {
            this.productPriceChange = false;
        }
    }

    changedValue() {
        this.changeMade = true;
    }

    confirmDeliveryChange() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'no-info-dialog';
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {type: 'delivery'};
        const confirmDialog = this.dialog.open(ConfirmPriceChangeDialogComponent, dialogConfig);

        confirmDialog.afterClosed().subscribe((accepted) => {
            if (accepted) {
                this.apiService.changeDeliveryPrice(this.deliveries).subscribe(
                    (data: any[]) => {
                        this.deliveries = data;
                        this.noChangeDeliveries = JSON.stringify(data);
                        this.deliveries = [...JSON.parse(this.noChangeDeliveries)];
                        this.changeMade = false;
                        this.deliveryPriceChange = false;
                        this.snackBar.open(this.translate.instant('saved'), 'X', {
                            duration: 3000
                        });
                    }
                ), (error) => {
                    this.snackBar.open(this.translate.instant('thereWasAnError'), 'X', {
                        duration: 3000
                    });
                }
            }
        });
    }

    confirmProductChange() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'no-info-dialog';
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {type: 'product'};
        const confirmDialog = this.dialog.open(ConfirmPriceChangeDialogComponent, dialogConfig);

        confirmDialog.afterClosed().subscribe((accepted) => {
            if (accepted) {
                this.apiService.changeProductPrices({products: this.products, isDiscount: this.isDiscount}).subscribe(
                    (data: any) => {
                        this.products = data.products;
                        this.noChangeProducts = JSON.stringify(data.products);
                        this.products = [...JSON.parse(this.noChangeProducts)];
                        this.changeMade = false;
                        this.productPriceChange = false;
                        this.snackBar.open(this.translate.instant('saved'), 'X', {
                            duration: 3000
                        });
                    }
                ), (error) => {
                    this.snackBar.open(this.translate.instant('thereWasAnError'), 'X', {
                        duration: 3000
                    });
                }
            }
        });

    }

    exit() {
        if (this.changeMade) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.panelClass = 'no-info-dialog';
            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            dialogConfig.data = {type: 'exit'};
            const confirmDialog = this.dialog.open(ConfirmPriceChangeDialogComponent, dialogConfig);

            confirmDialog.afterClosed().subscribe((accepted) => {
                if (accepted) {
                    this.productPriceChange = false;
                    this.deliveryPriceChange = false;
                    this.changeMade = false;
                    this.products = JSON.parse(this.noChangeProducts);
                    this.deliveries = JSON.parse(this.noChangeDeliveries);


                }
            });
        } else {
            this.productPriceChange = false;
            this.deliveryPriceChange = false;
            this.changeMade = false;
        }

    }

    ngOnDestroy(): void {
        this.countrySubscription.unsubscribe();
    }


}
