import {
  Component,
  ElementRef,
  EventEmitter,
  isDevMode,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {ApiService} from "../api.service";
import 'rxjs/add/observable/interval';
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {MatSidenav} from "@angular/material/sidenav";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Admin, OrderItem} from "../models";
import {DataService} from "../data-service.service";
import {TranslateService} from "@ngx-translate/core";
import {MessagingService} from "../shared/messaging.service";
import {AppointmentComponent} from "../appointment/appointment.component";
import {
  fadeInDownOnEnterAnimation,
  fadeOutUpOnLeaveAnimation,
  slideInUpOnEnterAnimation,
  slideOutDownOnLeaveAnimation
} from "angular-animations";
import {saveAs} from "file-saver";
import {environment} from "../../environments/environment";
import {AllRequestsComponent} from "../all-requests/all-requests.component";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  animations: [
    fadeInDownOnEnterAnimation(),
    fadeOutUpOnLeaveAnimation()
  ]
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild('navbarToggler', {static: true}) navbarToggler: ElementRef;
  @ViewChild('openNavBar', {static: true}) openNavBar: ElementRef;

  address;
  user: Admin;
  country = "rs";
  mode = 'side';
  selectedLanguage = "en";
  unreadNotifications = 0;
  notifications = [];
  navOpen = true;
  newNotification = false;
  notificationPage = 0;
  openedNavbar = false;
  navbarType = 'report';
  baseUrl = environment.url;
  reports = [];
  allChecked = false;
  @ViewChild('rightSidenav', {static: true}) public sidenav: MatSidenav;
  @ViewChild('sideNav', {static: true}) public leftSidenav: MatSidenav;
  type = '';
  allCoutnries = this.translate.instant('allCountries');


  contries: Array<any> = [
    {name: this.allCoutnries, value: 'all'},
    {name: 'serbia', value: 'rs'},
    {name: 'bih', value: 'ba'},
    {name: 'croatia', value: 'hr'},
    {name: 'romania', value: 'ro'},
    {name: 'bulgaria', value: 'bg'},
    {name: 'turkey', value: 'tr'},
    {name: 'hungary', value: 'hu'},
    {name: 'eu', value: 'eu'}];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private render: Renderer2,
    public router: Router,
    private service: ApiService,
    private dataService: DataService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private messagingService: MessagingService
  ) {
    JSON.parse(localStorage.getItem('adminYolo')).locale !== null
      ? translate.use(JSON.parse(localStorage.getItem('adminYolo')).locale)
      : translate.use('en');

    JSON.parse(localStorage.getItem('adminYolo')).locale !== null
      ? this.selectedLanguage = JSON.parse(localStorage.getItem('adminYolo')).locale
      : this.selectedLanguage = 'en';

    console.log('language   ' + this.selectedLanguage);

    this.translate.get('allCountries').subscribe((text: string) => {
      this.allCoutnries = text;
      const index = this.contries.findIndex((x) => x.value == 'all');
      if (this.contries[index]) {
        this.contries[index].name = this.allCoutnries;
      }
    });
  }

  ngOnInit() {
    const env = localStorage.getItem("env")
    this.changeType(env);
    this.dataService.country.subscribe((country) => {
      this.country = country;
    });
    this.dataService.allChecked.subscribe((data) => {
      this.allChecked = data;
    });
    if (isDevMode()) {
      this.type = 'Development';
      this.changeType(this.type);
    } else {
      this.type = 'Production';
      this.changeType(this.type);
    }
    const w = window.innerWidth;
    if (w < 770) {
      this.navOpen = false;
      this.mode = 'over';
    } else {
      this.mode = 'side';
    }
    this.dataService.close.subscribe(
      data => this.newNotification = false
    );
    window.onresize = (e) => {
      this.checkMenu();
    };
    this.checkMenu();
    if (localStorage.getItem('country')) {
      this.country = localStorage.getItem('country');
    }
    this.user = JSON.parse(localStorage.getItem('adminYolo'));

    //Legacy code
    this.service.getUserPermissionsForLocaleStorage(this.user.id).subscribe(
      user => {
        localStorage.setItem('adminYolo', JSON.stringify(user));
      }
    )

    if (this.user) {
      this.dataService.changeLanguage(this.user.locale);
      if (!this.user.fullView) {
        localStorage.setItem('country', this.user.country);
        this.country = this.user.country;
        this.dataService.changeCountry(this.user.country);

      }
    }

    this.service.getProducts({isActive: 1}).subscribe(
      data => {
        const pr = [];
        pr['products'] = data;
        const products = pr['products']['products'];
        localStorage.setItem('products', JSON.stringify(products));
      }
    );
    this.service.checkAdmin().subscribe((res) => {
      console.log('OK');
    }, (err) => {
      this.service.logout();
      this.router.navigate(['/']);
    });
    this.service.getUnreadNotifications().subscribe(
      data => {
        const unread = [];
        unread['n'] = data;
        this.unreadNotifications = unread['n'];
      }
    );
    this.service.allNotifications(0).subscribe(
      data => {
        // console.log(data);
        const not = [];
        not['notif'] = data;
        this.notifications = not['notif'];
      }
    );
    this.dataService.country.subscribe((country) => {
      console.log("country like change- " + country);
    });
    this.dataService.navbar.subscribe((data) => {
      if (data === 'not') {
        this.toggleNavbarNot();
      } else if (data === 'report') {
        this.toggleNavbarReport();
      }

    });

    this.dataService.country.subscribe((country) => {
      console.log("country like change- " + country);
    });

    window.navigator.serviceWorker.addEventListener('message', event => {
      if (event['data']['firebase-messaging-msg-data']) {
        this.handleNotification(event['data']['firebase-messaging-msg-data']);
      } else {
        this.handleNotification(event['data']);
      }
    });

    this.dataService.notification$.subscribe(data => {
      this.handleNotification(data);
    });

  }

  handleNotification(data) {
    if (data !== null) {
      if (data['data'].senderId !== this.user.id) {
        this.unreadNotifications++;
        this.newNotification = true;
        setTimeout(() => {
          this.newNotification = false;
        }, 3000);

        if (data['data'].action == "download8") {
          console.log("PUSH MAIN HANDLE - DOWNLOAD 8");
          const fileName = data['data'].value.split("/space/print/")[1];
          const url = data['data'].value.split("/space/")[1];
          this.service.autoDownloadAlbum8(url).subscribe(file => {
            saveAs(file, fileName);
          })
        } else if (data['data'].action == "downloadYoloBook") {
          console.log("PUSH MAIN HANDLE - DOWNLOAD YOLOBOOK");
          const fileName = data['data'].value.split("/space/print/")[1];
          const url = data['data'].value.split("/space/")[1];
          this.service.autoDownloadYoloBook(url).subscribe(file => {
            saveAs(file, fileName);
          })
        } else if (data['data'].action == "download6") {
          console.log("PUSH MAIN HANDLE - DOWNLOAD YOLOBOOK");
          const fileName = data['data'].value.split("/space/print/")[1];
          const url = data['data'].value.split("/space/")[1];
          this.service.autoDownloadYoloBook(url).subscribe(file => {
            saveAs(file, fileName);
          })
        } else if (data['data'].action == "downloadYoloBookXL") {
          console.log("PUSH MAIN HANDLE - DOWNLOAD YOLOBOOK");
          const fileName = data['data'].value.split("/space/print/")[1];
          const url = data['data'].value.split("/space/")[1];
          this.service.autoDownloadYoloBook(url).subscribe(file => {
            saveAs(file, fileName);
          })
        } else if (data['data'].action == "downloadYoloBox") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOBOX");
          const fileName = data['data'].value.split("/space/print/")[1];
          const url = data['data'].value.split("/space/")[1];
          this.service.autoDownloadYoloBox(url).subscribe(file => {
            saveAs(file, fileName);
          })
        } else if (data['data'].action == "downloadYoloFrame1") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOFRAME 1");
          const fileName = data['data'].value.split("/space/print/")[1];
          const url = data['data'].value.split("/space/")[1];
          this.service.autoDownloadYoloFrame(url).subscribe(file => {
            saveAs(file, fileName);
          })
        } else if (data['data'].action == "downloadYoloFrame4") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOFRAME 4");
          const fileName = data['data'].value.split("/space/print/")[1];
          const url = data['data'].value.split("/space/")[1];
          this.service.autoDownloadYoloFrame(url).subscribe(file => {
            saveAs(file, fileName);
          })

        } else if (data['data'].action == "downloadYoloFrameXL1") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOFRAME XL 1");
          const fileName = data['data'].value.split("/space/print/")[1];
          const url = data['data'].value.split("/space/")[1];
          this.service.autoDownloadYoloFrame(url).subscribe(file => {
            saveAs(file, fileName);
          })
        } else if (data['data'].action == "downloadYoloFrameXL15") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOFRAME XL 15");
          const fileName = data['data'].value.split("/space/print/")[1];
          const url = data['data'].value.split("/space/")[1];
          this.service.autoDownloadYoloFrame(url).subscribe(file => {
            saveAs(file, fileName);
          })
        } else if (data['data'].action == "downloadYoloFrameXLH1") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOFRAME XL-H 1");
          const fileName = data['data'].value.split("/space/print/")[1];
          const url = data['data'].value.split("/space/")[1];
          this.service.autoDownloadYoloFrame(url).subscribe(file => {
            saveAs(file, fileName);
          })
        }
      }
    }
  }


  ngOnDestroy() {
  }


  toggle($event) {
    this.navbarToggler.nativeElement.click();
  }


  openNotification(not) {
    if (not.action === 'download8') {
      const url = this.baseUrl + not.value;
      window.open(url, "_blank");
      // const fileName = not.value.split("/space/print/")[1];
      // const blob = new Blob([url], { type: 'image/pdf' });
      // console.log(fileName);
      // saveAs(blob, fileName);
    } else if (not.action === 'sendingsXLS') {
      const url = not.value;
      console.log('url  ' + url);
      this.service.downloadSpendingsXls(url).subscribe(
        res => {
          saveAs(res, 'sendings_' + new Date().toISOString() + '.xls');
        }, err => {
          const index = this.notifications.findIndex((x) => x.id == not.id);
          this.notifications[index].description = this.translate.instant('fileExpired');
          this.service.updateNotification(not).subscribe();
        }
      )
    }  else if (not.action === 'sendingsXLSX') {
      const url = not.value;
      console.log('url  ' + url);
      this.service.downloadSpendingsXls(url).subscribe(
        res => {
          saveAs(res, 'sendings_' + new Date().toISOString() + '.xlsx');
        }, err => {
          const index = this.notifications.findIndex((x) => x.id == not.id);
          this.notifications[index].description = this.translate.instant('fileExpired');
          this.service.updateNotification(not).subscribe();
        }
      )
    }else if (not.action === 'sendingsPDF') {
      const url = not.value;
      console.log('url  ' + url);
      this.service.downloadSpendingsPdf(url).subscribe(
        res => {
          saveAs(res, 'sendings_' + new Date().toISOString() + '.pdf');
        }, err => {
          const index = this.notifications.findIndex((x) => x.id == not.id);
          this.notifications[index].description = this.translate.instant('fileExpired');
          this.service.updateNotification(not).subscribe();
        }
      )
    } else if (not.action === 'outgoingInvoices') {
      const url = not.value;
      console.log('url  ' + url);
      this.service.downloadInvoiceBook(url).subscribe(
        res => {
          saveAs(res, 'invoices_' + new Date().toISOString() + '.xlsx');
        }, err => {
          const index = this.notifications.findIndex((x) => x.id == not.id);
          this.notifications[index].description = this.translate.instant('fileExpired');
          this.service.updateNotification(not).subscribe();
        }
      )
    } else if (not.action == 'cancelDayOff' || not.action == 'addDayOff' || not.action == 'newAppointment' || not.action == 'cancelAppointment' || not.action == 'declinedDayOff') {
      const dialogConfig = new MatDialogConfig();
      // dialogConfig.width = '80%';
      // dialogConfig.height = '40%';
      dialogConfig.panelClass = 'event-info-dialog';
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      this.dialog.open(AllRequestsComponent, dialogConfig);
      this.service.getDayOffs().subscribe(
        data2 => {
          this.dataService.changeDayOffs.next(data2['count']);
        }
      );
    }
    else if(not.action == 'DOWNLOAD_SUCCESSFUL') {
      const url = not.value;
      this.service.downloadExportUsersXls(url).subscribe(
        res => {
          saveAs(res, 'sendings_' + new Date().toISOString() + '.xlsx');
        }, err => {
          const index = this.notifications.findIndex((x) => x.id == not.id);
          this.notifications[index].description = this.translate.instant('fileExpired');
          this.service.updateNotification(not).subscribe();
        });
    }
    else if(not.action == 'DOWNLOAD_SUCCESSFUL_RO_POST_OFFICE') {
      const url = not.value;
      this.service.downloadRoPostOffice(url).subscribe(
        res => {
          saveAs(res, 'ro_post_office_' + new Date().toISOString() + '.pdf');
        }, err => {
          const index = this.notifications.findIndex((x) => x.id == not.id);
          this.notifications[index].description = this.translate.instant('fileExpired');
          this.service.updateNotification(not).subscribe();
        });
    }
  }

  toggleNavbarReport() {
    this.navbarType = 'report';
    const el = this.openNavBar.nativeElement;
    el.click();
  }

  toggleNavBar() {
    this.navOpen = !this.navOpen;
  }

  getPageName() {
    if (this.router.url.includes("users")) {
      return this.translate.instant('users');
    } else if (this.router.url.includes("stat")) {
      return this.translate.instant('statistics');
    } else if (this.router.url.includes("campaign")) {
      return this.translate.instant('campaigns');
    } else if (this.router.url.includes("survey")) {
      return this.translate.instant('surveys');
    }
  }

  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  toggleNavbarNot() {
    this.service.allNotifications(0).subscribe(
      data => {
        console.log(data);
        const not = [];
        not['notif'] = data;
        this.notifications = not['notif'];
      }
    );
    this.service.readNotifications().subscribe(
      data => {
        this.unreadNotifications = 0;
      }
    );
    this.navbarType = 'not';
    const el = this.openNavBar.nativeElement;
    el.click();
  }

  onLogout($event) {
    this.service.logout().subscribe();
    localStorage.removeItem('adminYolo');
    this.router.navigate(['/']);
  }

  toggleReport(id) {
    this.service.toggleReport(id).subscribe(
      data => {
        this.service.getMyReports().subscribe(
          data2 => {
            const r = [];
            r['rep'] = data2;
            this.reports = r['rep'];
            if (this.reports.filter((x) => x.checked === false).length == 0) {
              this.dataService.changeChecked(true);
            } else {
              this.dataService.changeChecked(false);
            }
            console.log(this.allChecked);
          }
        )
      }
    );
  }


  checkMenu() {
    const w = window.innerWidth;
    this.navOpen = w > 770;

  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  onNavClick(address: string, fragment = '') {
    this.address = address;
    if (fragment != '') {
      this.router.navigate([this.address], {fragment: fragment});
    } else {
      this.router.navigate([this.address]);
    }
    const w = window.innerWidth;
    if (w < 770) {
      this.navOpen = false;
      this.mode = 'over';
    } else {
      this.mode = 'side';
    }
  }

  onClosed() {
    this.navOpen = false;
    if (this.address) {
      this.router.navigate([this.address]);
    }
  }

  addAppointment() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.width = '80%';
    // dialogConfig.height = '40%';
    dialogConfig.panelClass = 'event-info-dialog';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(AppointmentComponent, dialogConfig);
  }

  onOpened() {
    console.log(this.navbarType);
    if (this.navbarType === 'not') {
      this.service.allNotifications(0).subscribe(
        data => {
          console.log(data);
          const not = [];
          not['notif'] = data;
          this.notifications = not['notif'];
        }
      );
      this.service.readNotifications().subscribe(
        data => {
          this.unreadNotifications = 0;
        }
      );
    }

  }

  onCountrySelected(event) {
    this.dataService.countryChange.next(event);
    this.country = event;
    console.log(this.country);
    localStorage.setItem('country', event);
    this.dataService.changeCountry(event);
  }

  onLanguageSelected(locale) {
    this.dataService.changeLanguage(locale);
    localStorage.setItem('locale', locale);
    this.selectedLanguage = locale;
    this.translate.use(locale);
    this.translate.get('allCountries').subscribe((text: string) => {
      console.log('res', this.contries);
      this.allCoutnries = text;
      const index = this.contries.findIndex((x) => x.value == '');
      this.contries[index].name = this.allCoutnries;
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['newNot']
    });
  }

  getImageOutOfObject(object) {
    if (object.image) {
      return object.image;
    } else {
      return false;
    }
  }

  onScrollDown() {
    console.log('scroll!');
    this.notificationPage++;
    this.service.allNotifications(this.notificationPage).subscribe(
      data => {
        const ne = [];
        ne['new'] = data;
        const newNot = ne['new'];
        console.log(newNot);
        if (newNot.length > 0) {
          for (const n of newNot) {
            this.notifications.push(n);
          }
        } else {
          this.notificationPage = 0;
        }
        console.log(this.notifications.length);
      }
    );
  }

  changeType(selectedType) {
    if (selectedType == undefined || selectedType == 'production') {
      this.service.changeEnv("https://api.yolobook.com");
      this.service.changeEnvApi2("https://prod.yolobook.com");
      environment.url = "https://api.yolobook.com";
      environment.urlV2 = "https://prod.yolobook.com";

      this.type = 'Production';
    } else if (selectedType == 'development') {
      this.service.changeEnv("https://dev.yolobook.com");
      this.service.changeEnvApi2("https://stage.yolobook.com");
      environment.url = "https://dev.yolobook.com";
      environment.urlV2 = "https://stage.yolobook.com";
      this.type = 'Development';
    }
    localStorage.setItem("env", this.type);
  }

  getCountryFlag(country: String): String {
    return "https://flagpedia.net/data/flags/small/" + country + ".png";
  }
}
