import {Component, Inject, OnInit} from '@angular/core';
import {Admin} from "../models";
import {DataService} from "../data-service.service";
import {MAT_DIALOG_DATA} from "@angular/material";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

import {DialogData} from "../vacation/vacation.component";
import {ApiService} from "../api.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  admin: Admin;
  positions = [];
  sectors = [];
  disablePermission = true;
  isLoading = false;
  permissions = [];
  message = '';
  repeatPassword = '';
  loggedInAdmin: Admin;
  contries: Array<any> = [{name: 'Srbija', value: 'rs'} , {name: 'Bosna', value: 'ba'}, {name: 'Hrvatska', value: 'hr'}, {name: 'Rumunija', value: 'ro'}, {name: 'Turska', value: 'tr'}, {name: 'Bugarska', value: 'bg'}];

  constructor(private translate: TranslateService, private snackBar: MatSnackBar, private dialogRef: MatDialogRef<EmployeeComponent>, private apiService: ApiService, private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  ngOnInit() {
    this.loggedInAdmin = JSON.parse(localStorage.getItem('adminYolo'));
    if (this.data['mode'] === 'new'){
      this.admin = new class implements Admin {
        dayOffs: any;
        permissions: any;
        sectorId: any;
        positionId: any;
        address: string;
        apiKey: string;
        country: string;
        dateCreated: string;
        dateOfBirth: string;
        email: string;
        fullName: string;
        fullView: string;
        id: number;
        image: string;
        locale: string;
        phone: string;
        position: string;
        role: number;
        roleId: number;
        sector: string;
        skype: string;
        username: string;
      };

      this.disablePermission = true;

  } else {
    this.admin = this.data['admin'];
      this.apiService.getPermissionsForUser(this.admin).subscribe(
        data =>{

          console.log(data);

          const p = [];
          p['p'] = data;
          this.permissions = p['p'];
          this.disablePermission = false;
        }
      )
    }

    this.apiService.getPositions().subscribe(
      data => {
        const ps = [];
        ps['ps'] = data;
        this.positions = ps['ps'];
      }
    );


    this.apiService.sectorsList().subscribe(
      data => {
        const s = [];
        s['sectors'] = data;
        this.sectors = s['sectors'];
      }
    )
  }

  changeSector() {
    this.apiService.getPermissions(this.admin.sectorId).subscribe(
      data =>{
        const p = [];
        p['p'] = data;
        this.permissions = p['p'];
        this.disablePermission = false;
      }
    )
  }

  confirmPermission() {
    this.apiService.updateOrCreatePermissions(this.admin.id, this.permissions).subscribe(
      data => {
        const p = [];
        p['p'] = data;
        this.permissions = p['p'];
        this.dialogRef.close();
        this.dataService.newRefresh('new');
      }
    );
  }

  update(row, type, value){
    const id = row.id;
    const index = this.permissions.findIndex((x) => x.id === id);
    if (type === 'view'){
      this.permissions[index].view = value;
    } else if (type === 'edit'){
      this.permissions[index].edit = value;
    }
    console.log(this.permissions);
  }


  onSelectFile(files: FileList) {
    console.log(files);
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image', fileToUpload, 'image');
    console.log(formData);
    this.apiService.uploadImage(formData).subscribe(
      data => {
        console.log(data);
        this.admin.image = data['image'];
      });
  }

  canChangeSector() {
    if (this.loggedInAdmin.roleId == 1) {
      return true;
    } else {
      if (this.data['admin']) {
        if (this.admin.id == this.data['admin']['id']) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  confirm(){
    if (this.admin.username == null || this.admin.email == null) {
      this.snackBar.open(this.translate.instant("completeAllFields"), "Ok", {duration : 2000});
      return;
    }
    console.log(this.admin);
    if (this.data['mode'] === 'new'){
      this.apiService.addAdmin(this.admin).subscribe(
        data => {
          const r = [];
          r['d'] = data;
          console.log(data);
          const id = r['d'].id;
          this.apiService.addPermissions(id, this.permissions).subscribe(
            data2 => {
              const p = [];
              p['p'] = data2;
              this.permissions = p['p'];
              console.log(this.permissions);

            }
          );
          this.dialogRef.close();
          this.snackBar.open(this.translate.instant("saved"), "Ok", {duration : 2000});
          this.dataService.newRefresh('new');
        },
        err =>{
          console.log(err);
          this.message = 'thereWasAnError';
        }
      )
    } else {
      if (this.admin.password != '' && this.repeatPassword != ''){
        if (this.admin.password != this.repeatPassword){
          this.snackBar.open(this.translate.instant("passwordsAreNotTheSame"), "Ok", {duration : 2000});
          return;
        }
      }
      this.apiService.updateAdmin(this.admin).subscribe(
        data => {
          this.dialogRef.close();
          this.snackBar.open(this.translate.instant("saved"), "Ok", {duration : 2000});
          this.dataService.newRefresh('new');
        },
        err =>{
          console.log(err);
          this.snackBar.open(this.translate.instant("thereWasAnError"), "Ok", {duration : 2000});
        }
      );
    }

  }

}
