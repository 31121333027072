import {Component, OnInit, ViewChild} from '@angular/core';
import {Admin, Cost, CoverStat, Info, OrderStat, ProfitStat, UserStat} from "../models";
import {ApiService} from "../api.service";
import {MatInput} from "@angular/material/input";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DataService} from "../data-service.service";
import {Router} from "@angular/router";
import {DatePipe} from '@angular/common'
import {TranslateService} from "@ngx-translate/core";
import {Colors} from "../Colors";
import {ConfirmModalComponent} from "../confirm-modal/confirm-modal.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.css'],
  providers: [ApiService]
})
export class CostsComponent implements OnInit {

  colors = Colors;
  public chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          steps: 0.1,
          stepValue: 0.1,
          beginAtZero: true
        }
      }]
    }
  };

  public profitOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          steps: 0.1,
          stepValue: 0.1
        }
      }]
    }
  };

  stackedBar: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true,
        display: true,
        min: 0,
        ticks: {
          min: 0,
          beginAtZero: true
        }
      }]
    }
  };

  months: Array<any> = [{name: 'Januar', value: '01-01-2018'}, {name: 'Februar', value: '01-02-2018'}, {
    name: 'Mart',
    value: '01-03-2018'
  },
    {name: 'April', value: '01-04-2018'}, {name: 'Maj', value: '01-05-2018'}, {
      name: 'Jun',
      value: '01-06-2018'
    }, {name: 'Jul', value: '01-07-2018'},
    {name: 'Avgust', value: '01-08-2018'}, {name: 'Septembar', value: '01-09-2018'}, {
      name: 'Oktobar',
      value: '01-10-2018'
    }
    , {name: 'Novembar', value: '01-11-2018'}, {name: 'Decembar', value: '01-12-2018'}];

  contries: Array<any> = [{name: 'Srbija', value: 'rs'}, {name: 'Bosna', value: 'ba'}, {
    name: 'Hrvatska',
    value: 'hr'
  }, {name: 'Turska', value: 'tr'}, {name: 'Bugarska', value: 'bg'}];

  info: Info;

  covers: number[] = [];
  coversLabel: string[] = [];

  users: number[] = [];
  usersLabel: string[] = [];

  orders: Array<any> = [];
  ordersLabel: string[] = [];

  profits: Array<any> = [];
  sentProfits: Array<any> = [];
  profitsLabel: string[] = [];


  @ViewChild('fromInput', {
    read: MatInput,
    static: true
  }) fromInput: MatInput;

  @ViewChild('toInput', {
    read: MatInput,
    static: true
  }) toInput: MatInput;

  date = new Date();
  fromDate  = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toDateString();
  toDate    = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toDateString();
  fromD     = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toISOString();
  toD       = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toISOString();

  coverTo = 0;
  coverFrom = 0;

  profitTotal = 0;
  incomeTotal = 0;
  costTotal = 0;

  totalCount = 0;
  totalOneCount = 0;
  totalTwoCount = 0;
  totalThreeCount = 0;
  totalFrameCount = 0;
  totalBoxCount = 0;
  hasTo: false;
  cost: Cost = {
    id: 0,
    to: null,
    from: null,
    created_at: '',
    cost: 0,
    desc: '',
    type: null,
    repeat: false,
    repeat_every: 1,
    uuid: ''
  };
  costList: Cost[] = [];
  messages = {emptyMessage: 'Nema rezultata', totalMessage: 'Ukupno'};
  isLoading = false;
  user: Admin;
  types: Array<any> = [{name: 'Marketing', value: 1}, {name: 'IT', value: 2}, {name: 'OPEX', value: 3}];
  allowedEdit = false;
  sentCostTotal: number = 0;
  sentProfitTotal: number = 0;
  sentIncomeTotal: number = 0;
  sentProfitsLabel: string[] = [];

  constructor(private datePipe: DatePipe,
              private router: Router,
              private service: ApiService,
              private snackBar: MatSnackBar,
              private dataService: DataService,
              private translate: TranslateService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('finance')) {
      this.router.navigate(['/']);
    }
    this.dataService.country.subscribe((country) => {
      this.getCosts();
    });
    this.dataService.country.subscribe((country) => {
      // this.getCoverStat();
      // this.getOrderStat();
      // this.getUsersStat();
      // this.getInfo();
      // this.getProfitStat();
      // this.getSentProfitStat();
    });
  }


  getCoverStat() {
    this.coversLabel = [];
    this.service.getCoversStat(this.fromDate, this.toDate).subscribe((result: CoverStat[]) => {
      this.covers = [];
      result.forEach((coverStat) => {
        this.covers.push(coverStat.count);
        this.coversLabel.push('ID: ' + String(coverStat.coverId));
      });
    });
  }

  getUsersStat() {
    this.usersLabel = [];
    this.service.getUsersStat(this.fromDate, this.toDate).subscribe((result: UserStat[]) => {
      this.users = [];
      result.forEach((userStat: UserStat) => {
        this.users.push(userStat.count);
        this.usersLabel.push(this.datePipe.transform(userStat.created_at, 'dd.MM.'));
      });
    });
  }

  getSentProfitStat() {
    this.sentProfitsLabel = [];
    this.sentIncomeTotal = 0;
    this.sentProfitTotal = 0;
    this.sentCostTotal = 0;
    this.service.getSentProfitsStat(this.fromDate, this.toDate).subscribe((result: ProfitStat[]) => {
      this.sentProfits = [];

      let incomeArray: number[] = [];
      let outcomeArray: number[] = [];
      let profitArray: number[] = [];

      result.forEach((profit: ProfitStat) => {
        this.sentCostTotal = this.sentCostTotal + +profit.cost + +profit.ruc;
        this.sentProfitTotal = this.sentProfitTotal + +profit.total - (+profit.cost + +profit.ruc);
        this.sentIncomeTotal = this.sentIncomeTotal + +profit.total;

        incomeArray.push(profit.total);
        outcomeArray.push(profit.cost + profit.ruc);
        profitArray.push(profit.total - profit.cost - profit.ruc);
        console.log(profit.datum);
        this.sentProfitsLabel.push(this.datePipe.transform(profit.datum, 'dd.MM.'));
      });

      this.sentProfits.push(
        {data: incomeArray, label: this.translate.instant('sent')},
      );

      console.log(this.sentProfits);

    });
  }

  getProfitStat() {
    this.profitsLabel = [];
    this.incomeTotal = 0;
    this.profitTotal = 0;
    this.costTotal = 0;
    this.service.getProfitsStat(this.fromDate, this.toDate).subscribe((result: ProfitStat[]) => {
      console.log('result   ' + JSON.stringify(result));
      this.profits = [];

      let incomeArray: number[] = [];
      let outcomeArray: number[] = [];
      let profitArray: number[] = [];

      result.forEach((profit: ProfitStat) => {
        this.costTotal = this.costTotal + +profit.cost + +profit.ruc;
        this.profitTotal = this.profitTotal + +profit.total - (+profit.cost + +profit.ruc);
        this.incomeTotal = this.incomeTotal + +profit.total;

        incomeArray.push(profit.total);
        outcomeArray.push(profit.cost + profit.ruc);
        profitArray.push(profit.total - profit.cost - profit.ruc);
        this.profitsLabel.push(this.datePipe.transform(profit.datum, 'dd.MM.'));
      });

      this.profits.push(
        {data: profitArray, label: 'Profit'},
        {data: incomeArray, label: 'Prihod'},
        {data: outcomeArray, label: 'Troškovi'}
      );

      console.log(this.profits);

    });
  }

  onEnter() {

  }

  onDateChanged($event) {
    console.log($event);
    this.fromDate = this.datePipe.transform(this.fromD, 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(this.toD, 'yyyy-MM-dd');
    this.getOrderStat();
    this.getProfitStat();
    this.getSentProfitStat();
    this.getUsersStat();
    this.getCoverStat();
  }

  onReset() {
    this.fromDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1).toISOString();
    this.toDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).toISOString();
    this.getOrderStat();
    this.getProfitStat();
    this.getSentProfitStat();
    this.getUsersStat();
    this.getCoverStat();
  }

  getOrderStat() {
    this.ordersLabel = [];
    this.totalFrameCount = 0;
    this.totalCount = 0;
    this.totalBoxCount = 0;
    this.totalOneCount = 0;
    this.totalTwoCount = 0;
    this.totalThreeCount = 0;

    this.service.getOrdersStat(this.fromDate, this.toDate).subscribe((result: OrderStat[]) => {
      this.orders = [];

      let oneArray: number[] = [];
      let twoArray: number[] = [];
      let threeArray: number[] = [];
      let frameArray: number[] = [];
      let boxArray: number[] = [];
      let totalArray: number[] = [];

      result.forEach((orderStat) => {
        this.totalCount = this.totalCount + orderStat.one + orderStat.two + orderStat.three + orderStat.yolobook
          + orderStat.yolobox + orderStat.frame;

        this.totalOneCount = this.totalOneCount + orderStat.one + orderStat.yolobook;
        this.totalTwoCount = this.totalTwoCount + orderStat.two;
        this.totalThreeCount = this.totalThreeCount + orderStat.three;
        this.totalBoxCount = this.totalBoxCount + orderStat.yolobox;
        this.totalFrameCount = this.totalFrameCount + orderStat.frame;

        oneArray.push(orderStat.one + orderStat.yolobook);
        twoArray.push(orderStat.two);
        threeArray.push(orderStat.three);
        totalArray.push(orderStat.total);
        frameArray.push(orderStat.frame);
        boxArray.push(orderStat.yolobox);
        this.ordersLabel.push(this.datePipe.transform(orderStat.datum, 'dd.MM.') + ' (' + orderStat.total + ')');
      });


      this.orders.push(
        {data: oneArray, label: 'Yolobook'},
        {data: twoArray, label: 'Yolobook (Duo)'},
        {data: threeArray, label: 'Yolobook (Trio)'},
        {data: frameArray, label: 'Frame'},
        {data: boxArray, label: 'Box'},
      );
    });
  }

  getInfo() {
    this.service.getInfo().subscribe((info: Info) => {
      console.log(info);
      this.info = info;
    });
  }

  onSelected($event) {
    console.log($event);
    this.getOrderStat();
    this.getProfitStat();
    this.getSentProfitStat();
    this.getUsersStat();
    this.getCoverStat();
  }

  onCountrySelected($event) {
    console.log($event);
  }


  checkPermission(page) {
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  addCost() {
    if (this.allowedEdit) {
      if (this.cost.repeat == false) {
        this.cost.repeat_every = null;
      }

      this.cost.from = new Date(this.cost.from).toDateString();

      if(this.cost.to != null)
      {
        this.cost.to = new Date(this.cost.to).toDateString();
      }

      this.service.addCost(this.cost).subscribe((res: Cost) => {
        this.getCosts();
        this.cost = {
          id: 0,
          to: this.cost.to,
          from: this.cost.from,
          created_at: '',
          cost: this.cost.cost,
          desc: '',
          type: this.cost.type,
          uuid: ''
        };
        this.snackBar.open('Trošak uspešno dodat.', '', {duration: 2000});

      }, (err) => {
        this.snackBar.open('GREŠKA!', '', {duration: 2000});
      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  getCosts() {
    this.isLoading = true;
    this.service.getAllCosts().subscribe((res: Cost[]) => {
      this.costList = res['data'];
      this.isLoading = false;
    });
  }

  onDeleteClick(uuid) {
    const openDeleteDialog = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      width: '400px',
      height: '200px',
      position: {
        top: '150px',
      },
      data: {
        data: '',
        message: this.translate.instant('areYouSureYouWantToDeleteCost'),
        mode: 'other',
      },
    });
    openDeleteDialog.afterClosed().subscribe((response) => {
      if (response) {
        this.service.deleteCost(uuid).subscribe((res) => {
          this.getCosts();
          this.snackBar.open('Trošak uspešno izbrisan.', '', {duration: 2000});
        }, (err) => {
          this.getCosts();
        });
      }});
  }

  onFromChanged($event) {
    console.log($event);
    this.cost.from = $event.value;
  }

  onToChanged($event) {
    console.log($event);
    this.cost.to = $event.value;
  }

  getCostType(value) {
    const index = this.types.findIndex(t => t.value == value);
    if (index != -1) {
      return this.types[index].name;
    } else {
      return '';
    }
  }

  protected readonly Colors = Colors;
}
