import { Injectable } from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/database";
import {DataService} from "../data-service.service";
import {AngularFireAuth} from "@angular/fire/auth";
import {ApiService} from "../api.service";
import {AngularFireMessaging} from "@angular/fire/messaging";
import {BehaviorSubject} from "rxjs/BehaviorSubject";



@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase,
    private dataService: DataService,
    private angularFireAuth: AngularFireAuth,
    private apiService: ApiService,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    console.log("Update TOKEN!")
    this.apiService.addDevice(token).subscribe(
      data => console.log(data)
    );
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    console.log("requestPermission TOKEN!")
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage(payload) {
    this.currentMessage.next(payload);
  }
}
