import {Component, OnDestroy, OnInit, ViewChild, AfterContentInit} from '@angular/core';
import {Router} from "@angular/router";
import {Admin, Filter, Order, OrderItem, OrderList} from "../models";
import {ApiService} from "../api.service";
import {MatDialogRef, MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatInput} from "@angular/material/input";
import {ConfirmComponent} from "../confirm/confirm.component";
import {Observable} from "rxjs/Observable";
import * as FileSaver from 'file-saver';
import {saveAs} from "file-saver";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {InfoComponent} from "../info/info.component";
import {DataService} from "../data-service.service";
import {id} from "@swimlane/ngx-datatable/release/utils";
import {CustomsComponent} from "../customs/customs.component";
import {DownloadByIdComponent} from "../download-by-id/download-by-id.component";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../environments/environment";
import {EmployeeComponent} from "../employee/employee.component";
import {MergeOrdersComponent} from "../merge-orders/merge-orders.component";
import {MachineRepairComponent} from "../machine-repair/machine-repair.component";
import {RefundDialogComponent} from "../refund-dialog/refund-dialog.component";
import {DownloadById6Component} from "../download-by-id6/download-by-id6.component";
import {UploadBannerComponent} from "../upload-banner/upload-banner.component";
import {FactureComponent} from "../facture/facture.component";
import {TemporalPriceModalComponent} from "../temporali-price-modal/temporal-price-modal.component";
import {FormGroup, FormBuilder} from "@angular/forms";
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {interval, Subscription} from "rxjs";
import {OrderHistoryComponent} from "../order-history/order-history.component";
import {RefundPopupComponent} from "../refund-popup/refund-popup.component";

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.css'],
  providers: [ApiService]
})
export class OrdersListComponent implements OnInit, OnDestroy, AfterContentInit {

  typeFilterForm: FormGroup = this.fb.group({
    type: [''],
    payingMethod: [''],
  })

  statusFilterForm: FormGroup = this.fb.group({
    status: [''],
    payingStatus: [''],
  })

  dateFilterForm: FormGroup = this.fb.group({
    fromDate: [''],
    toDate: [''],
  })

  selectFilterForm: FormGroup = this.fb.group({
    device: [''],
  })

  messages = {emptyMessage: 'Nema rezultata', totalMessage: 'Trenutno'};
  orders: OrderList[];
  selectedOrders: OrderList[] = [];
  rowHeight = 60;
  actionOpen = false;
  dataLoading = false;
  date: Date = new Date();
  ordersData: OrderList[] = [];
  infoObj: OrderList[];
  noMoreData = false;
  page = 0;
  allDataLoading = true;
  typeArray = [];
  hideCancelButton = false;
  private intervalId: any;

  stopPrevent: boolean;

  filter: Filter = {
    id: null,
    status: null,
    statusArray: null,
    typeArray: null,
    name: '',
    email: "",
    counts: null,
    fromId: null,
    isFast: null,
    isFree: null,
    phone: "",
    promo_text: "",
    toDate: "",
    toId: null,
    fromDate: "",
    firstView: 1,
    isPromo: null,
    album: null,
    itemsType: null,
    price: null,
    device: null,
    payingMethod: null,
    payingStatus: null,
    giftPackage: null,
    econt: null,
    withoutId: null,
    extraId: null
  };

  collums = {
    econt: true,
    delivery: true,
    status: true,
    name: true,
    email: true,
    remove: true,
    albums: false,
    packet: false,
    address: true,
    note: true,
    promo: true,
    free: true,
    fast: true,
    download: true,
    price: true,
    appVersion: true,
    country: true,
    payingMethod: true,
    payingStatus: true,
    giftPackage: true,
    sendingsExtra: true,
    upsellingAction: true,
    refund: true
  };

  isLoading = false;
  editing = {};
  limit = 100;
  country = "";
  ybDiff = -1;
  ybDiffXl = -1;
  ybDiffS = -1;
  ybDiffXlS = -1;
  ybCalDiff = -1;
  ybCalBasicDiff = -1;
  ybCalKidsDiff = -1;
  yhmini = -1;
  yhmaxi = -1;
  yhminiS = -1;
  yhmaxiS = -1;
  yStories = -1;
  yCanvas = -1;
  uploading = '';
  impartiallyDownloaded = '';
  processed = '';
  printing = '';
  uploadingTr = this.getTranslated('uploading');

  types: Array<any> = [
    {name: 'YoloBook Mini', value: [1]},
    {name: 'YoloBook Mini S', value: [25]},
    {name: 'YoloBox', value: [2]},
    {name: 'YoloFrame', value: 3},
    {name: 'YoloBook Maxi', value: 4},
    {name: 'YoloBook Maxi S', value: 24},
    {name: 'Memory Box', value: 32},
    {name: 'YoloVintage', value: 6},
    {name: 'YoloSquare Mini', value: 7},
    {name: 'YoloClassic', value: 8},
    {name: 'YoloOriginal', value: 9},
    {name: 'YoloSquare Maxi', value: 10},
    {name: 'YoloRegular', value: 11},
    {name: 'YoloSquare ', value: 12},
    {name: 'YoloCalendar - all', value: -20},
    {name: 'YoloCalendar - Basic', value: 20},
    {name: 'YoloCalendar - Kids', value: 21},
    {name: 'YoloHard Square', value: 36},
    {name: 'YoloHard Landscape', value: 37},
    {name: 'YoloHard Square S', value: 38},
    {name: 'YoloHard Landscape S', value: 39},
    {name: 'YoloTiles Square', value: 30},
    {name: 'YoloTiles Hexa', value: 31},
    {name: 'YoloPlanner', value: 33},
    {name: 'YoloNotes', value: 34},
    {name: 'YoloPremium', value: 35},
    {name: 'YoloPremium Cover', value: 46},
    {name: 'YoloStories', value: 40},
    {name: 'Yolo Canvas 30x30 + Text', value: 42},
    {name: 'Yolo Canvas 30x30', value: 43},
    {name: 'Yolo Canvas 20x30', value: 44},
    {name: 'Yolo Canvas 30x40', value: 45},
    {name: 'KK Partizan Stories', value: 47},
    {name: 'HCL KK Partizan (Aba, Euroleague)', value: 48},
    {name: 'Hard Cover Landscape Partizan', value: 49}




  ];

  status: Array<any> = [
    {name: 'uploading', value: 1},
    {name: 'impartiallyDownloaded', value: 2},
    {name: 'processed', value: 3},
    {name: 'printing', value: 4},
    {name: 'sent', value: 5},
    {name: 'delivered', value: 6},
    {name: 'leftReport', value: 7},
    {name: 'returning', value: 8},
    {name: 'returned', value: 9},
    {name: 'unsuccessful', value: 10},
    {name: 'notwanted', value: 11}
  ];

  paymentTypes: Array<any> = [
    {name: 'cash', value: 1},
    {name: 'card', value: 2},
    {name: 'bonusPoints', value: 3}
  ]

  filteredStatus: any[] = [];

  statusFilter: Array<any> = [
    {name: 'uploading', value: 1},
    {name: 'impartiallyDownloaded', value: 2},
    {name: 'processed', value: 3},
    {name: 'printing', value: 4},
    {name: 'sent', value: 5},
    {name: 'delivered', value: 6},
    {name: 'leftReport', value: 7},
    {name: 'returning', value: 8},
    {name: 'returned', value: 9},
    {name: 'notwanted', value: 11},
    {name: 'unsuccessful', value: 10},
    {name: 'all', value: null}
  ];

  yolobook8itemsToDownload: OrderItem[] = []; //Ovo je samo zbog skidanja 8 yolobook-ova istovremeno - provremena lista, pogledaj onSelect
  yolobook8XlitemsToDownload: OrderItem[] = [];
  yolobookS8itemsToDownload: OrderItem[] = []; //Ovo je samo zbog skidanja 8 yolobook-ova istovremeno - provremena lista, pogledaj onSelect
  yolobookS8XlitemsToDownload: OrderItem[] = [];
  yoloCalendarItemsToDownload: OrderItem[] = [];
  yoloCalendarBasicItemsToDownload: OrderItem[] = [];
  yoloCalendarKidsItemsToDownload: OrderItem[] = [];
  yoloHardMini: OrderItem[] = [];
  yoloHardMaxi: OrderItem[] = [];
  yoloHardMiniS: OrderItem[] = [];
  yoloHardMaxiS: OrderItem[] = [];
  yoloStories: OrderItem[] = [];

  itemsToDownload: OrderItem[] = [];
  itemsDownloadRefresh: OrderItem[] = [];
  itemsFailed: OrderItem[] = [];
  isDownloading = false;
  isEditing = false;
  allowedEdit = false;
  user: Admin;
  public obsevarble;
  public indeterminate = 'indeterminate';
  timer = null;
  miniId: Array<number> = [];
  maxiId: Array<number> = [];
  miniSId: Array<number> = [];
  maxiSId: Array<number> = [];
  storiesId: Array<number> = [];

  yoloBookSendings: Array<number> = [];
  yoloBookXLSendings: Array<number> = [];

  resetCounter: Subscription;

  @ViewChild('fromInput', {
    read: MatInput,
    static: true
  }) fromInput: MatInput;

  @ViewChild('toInput', {
    read: MatInput,
    static: true
  }) toInput: MatInput;

  selectedRows = [];

  @ViewChild('table', {static: true}) table: DatatableComponent;
  public showHeader = true;
  currentPage = 'orders';
  expandedRows = [];


  constructor(private apiService: ApiService,
              private snackBar: MatSnackBar,
              private mdDialog: MatDialog,
              private http: HttpClient,
              private dataService: DataService,
              private translate: TranslateService,
              private router: Router,
              private dialog: MatDialog,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    // reset svih nizova za brojanje porudzbina prilikom promena države
    this.intervalId = setInterval(() => {
      if(this.itemsDownloadRefresh.length > 0){

      }
      //TODO call API for returnuig download URL for each order
    }, 5000);

    this.resetCounter = this.dataService.resetCounter$.subscribe(res => {
      this.yolobook8itemsToDownload = [];
      this.yolobookS8itemsToDownload = [];
      this.yolobookS8XlitemsToDownload = [];
      this.ybDiff = -1;
      this.ybCalDiff = -1;
      this.ybCalBasicDiff = -1;
      this.ybCalKidsDiff = -1;
      this.ybDiffXl = -1;
      this.yoloCalendarItemsToDownload = [];
      this.yoloCalendarBasicItemsToDownload = [];
      this.yoloCalendarKidsItemsToDownload = [];
      this.yolobook8XlitemsToDownload = [];
      this.yoloHardMini = [];
      this.yoloHardMaxi = [];
      this.yoloHardMiniS = [];
      this.yoloHardMaxiS = [];
      this.yoloStories = [];

      this.typeFilterForm.patchValue({
        type: [''],
        payingMethod: [''],
      });

      this.statusFilterForm.patchValue({
        status: [''],
        payingStatus: [''],
      });

      this.dateFilterForm.patchValue({
        fromDate: [''],
        toDate: [''],
      });

      this.selectFilterForm.patchValue({
        device: '',
      });

      this.filteredStatus = [];
    });

    this.dataService.changeOrderObject.subscribe(
      (order: OrderList) => {
        const index = this.orders.findIndex((x) => x.id == order.id);
        this.orders[index] = order;
        this.orders = [...this.orders];
      }
    );
    this.dataService.mergedOrders.subscribe(
      data => {
        const arrayOfOrders: Array<string> = data['others'];
        arrayOfOrders.forEach((orderId) => {
          const idOfOrder = +orderId.trim();
          const index = this.orders.findIndex((x) => x.id == idOfOrder);
          this.orders.splice(index, 1);
        });
        const mainIndex = this.orders.findIndex((x) => x.id == data['first']['id']);
        this.orders[mainIndex] = data['first'];
        this.orders = [...this.orders];
        this.selectedOrders = [];
      }
    );
    this.checkResolution();
    this.user = JSON.parse(localStorage.getItem('adminYolo'));
    if (!this.checkPermission('orders')) {
      this.router.navigate(['/']);
    }
    window.addEventListener('scroll', this.scroll, true);
    if (localStorage.getItem('collums2'))
      this.collums = JSON.parse(localStorage.getItem('collums2'));

    if (localStorage.getItem('limit'))
      this.limit = +localStorage.getItem('limit');


    this.obsevarble = Observable.interval(1000 * 30).subscribe(() => {
      if (!this.isDownloading && !this.isEditing) {
        //this.getOrders();
      }
    });

    this.dataService.country.subscribe((country) => {
      console.log("Order country changed - " + country);
      this.country = country;
      this.onReset('');
    });

    this.dataService.locale.subscribe((locale) => {
      this.translate.defaultLang = locale !== null ? locale : 'sr';
      this.translate.use(locale !== null ? locale : 'sr');
      console.log('lang  ' + locale);
    });

    this.dataService.changeCountry(localStorage.getItem('country'));

    //HANDLE NOTIFICATIONS
    window.navigator.serviceWorker.addEventListener('message', event => {
      if (event['data']['firebase-messaging-msg-data']) {
        console.log("Firebase background notification");
        this.handleNotification(event['data']['firebase-messaging-msg-data']);
      } else {
        console.log("Firebase notification");
        this.handleNotification(event['data']);
      }
    });
    this.dataService.orderUpdateObservable.subscribe((socketMessage) => {
        if (socketMessage && socketMessage.order && socketMessage.admin !== this.user.id) {
          const order = socketMessage.order;
          const orderIndex = this.orders.findIndex((o) => o.id === order.id);
          if (orderIndex !== -1) {
            order.updated = true;
            this.orders[orderIndex] = order;
            this.orders = [...this.orders];

            setTimeout(() => {
              order.updated = false;
              this.orders[orderIndex] = order;
              this.orders = [...this.orders];
            }, 500);
          }
        }
      }
    );
  }


  openOrderHistory(orderId: number) {
    this.apiService.getOrderHistory(orderId).subscribe(
      (data:any) => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {id: orderId, histories: data};
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '60%';
        dialogConfig.height = '60%';
        dialogConfig.panelClass = 'history-dialog';

        this.dialog.open(OrderHistoryComponent, dialogConfig);
      },
      (err) => {
        this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
      }
    )

  }

  allowedOrderCopy() {
    if (this.user.permissions.find((p) => p.page == 'orders-super')) {
      return true;
    } else {
      return false;
    }
  }

  isEditable(order: Order | null, type: string | null): boolean {
    if (this.user.permissions.find((p) => p.page == 'orders-super')) {
      return true;
    }

    if (type == 'payment' || type == 'copyAlbum' || type == 'deleteAlbum') {
      return false;
    }

    if (order && order.status == 5) {
      return false;
    } else {
      return true;
    }
  }

  canRefund(): boolean {
    if (this.user.permissions.find((p) => p.page == 'refund')) {
      return true;
    }
  }

  openRefund(order) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {order};
    dialogConfig.panelClass = 'event-info-dialog';
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    const refundDialog = this.dialog.open(RefundPopupComponent, dialogConfig);
    refundDialog.afterClosed().subscribe((type) => {
      console.log(type);
      if (type && (type == 1 || type == 2 || type == 3)) {
        const data = {orderId: order.id, type};
        this.snackBar.open(this.translate.instant('invoiceRefundStarted'), '', {duration: 2000});
        this.apiService.refundOrder(data).subscribe((res: any) => {
            this.snackBar.open(this.translate.instant('invoiceRefunded', {orderId: order.id}), '', {duration: 2000});
            const index = this.orders.findIndex((o) => o.id == res.id);
            if (index != -1) {
              this.orders[index] = res;
              this.orders = [...this.orders];
            }
          },
          err => {
            this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
          })
      }
    });
  }

  deleteAlbum(albumId) {
    if (this.user.permissions.find((p) => p.page == 'orders-super')) {
      this.apiService.deleteAlbum(albumId).subscribe((order: OrderList) => {
        const index = this.orders.findIndex((x) => x.id == order.id);
        this.orders[index] = order;
        this.orders = [...this.orders];
        setTimeout(() => {
          this.expand();
        }, 100);
      }, (err) => {
        // console.log(err);

      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  copyOrder(orderId): void {
    this.apiService.checkIfOrderHasFiles(orderId).subscribe((data: any) => {
      const hasFiles = Boolean(data.hasFiles);
      let dialogRef: MatDialogRef<ConfirmComponent>;
      dialogRef = this.mdDialog.open(ConfirmComponent);
      dialogRef.componentInstance.title = hasFiles == true ? this.translate.instant("copyOrderWithFilesTitle") : this.translate.instant("copyOrderNoFilesTitle");
      dialogRef.componentInstance.message = hasFiles == true ? this.translate.instant("copyOrderWithFiles", {'orderId': orderId}) : this.translate.instant("copyOrderNoFiles", {'orderId': orderId});
      dialogRef.componentInstance.button = this.translate.instant("confirm");
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.apiService.copyOrder(orderId).subscribe((newOrder: Order) => {
            this.snackBar.open(this.translate.instant('copiedOrder', {orderId: newOrder.id}), 'OK');
          }, (err) => {
            this.snackBar.open(this.translate.instant('thereWasAnError'), 'OK');
          });
        } else {

        }
      });
    })

  }

  copyAlbum(albumId) {
    if (this.user.permissions.find((p) => p.page == 'orders-super')) {
      this.apiService.copyAlbum(albumId).subscribe((order: OrderList) => {
        const index = this.orders.findIndex((x) => x.id == order.id);
        this.orders[index] = order;
        this.orders = [...this.orders];
        setTimeout(() => {
          this.expand();
        }, 100);

      }, (err) => {
        // console.log(err);

      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  ngAfterContentInit() {
    console.log('  ChildComponent==>ngAfterContentInit');
    this.getColorOfOrders(null)
  }


  getCellClass({row, column, value}) {
    return value > 10 ? "color:red" : "color:green";
  }

  getColorOfOrders(value?: any): string {
    if (!value) {
      return '#000000';
    }

    if (value.payingMethod === 2) {
      if (value.payingStatus === 1) {
        return '#38acff';
      } else if (value.payingStatus === 2) {
        return '#f0a313';
      } else if (value.payingStatus === 3) {
        return '#f7ef54';
      } else if (value.payingStatus === 4) {
        return '#ff000d';
      } else if (value.payingStatus === 5 || value.payingStatus == 7) {
        return '#ab002b';
      } else if (value.payingStatus === 6) {
        return '#508c01';
      }
    }
  }

  getColorForPayingMethod(row: any): any {
    if (row.payingMethod === 2) {
      if (row.payingStatus === 1) {
        return 1;
      } else if (row.payingStatus === 2) {
        return 2;
      } else if (row.payingStatus === 3) {
        return 3;
      } else if (row.payingStatus === 4) {
        return 4;
      } else if (row.payingStatus === 5 || row.payingStatus == 7) {
        return 5;
      } else if (row.payingStatus === 6) {
        return 6;
      }
    }
  }

  toEditPrice(rowIndex) {
    if (this.orders[rowIndex].status == 3 || this.orders[rowIndex].status == 4) {
      this.isEditing = true;
      this.editing[rowIndex + '-price'] = true
    }
  }

  handleNotification(data) {
    if (data !== null) {
      if (data['data'].senderId !== this.user.id) {
        if (data['data'].action == "albumUpdated") {
          console.log("PUSH HANDLE - albumUpdated");
          const albums: OrderItem[] = JSON.parse(data['data'].object);
          this.refreshAlbumsOrderState(albums);
        } else if (data['data'].action == "albumAdded") {
          console.log("PUSH HANDLE - albumAdded");
        } else if (data['data'].action == "albumDeleted") {
          console.log("PUSH HANDLE - albumDeleted");
        } else if (data['data'].action == "download8") {
          console.log("PUSH HANDLE - DOWNLOAD 8");
        } else if (data['data'].action == "downloadYoloBook") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOBOOK");
        } else if (data['data'].action == "downloadYoloBox") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOBOX");
        } else if (data['data'].action == "downloadYoloFrame1") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOFRAME 1");
        } else if (data['data'].action == "downloadYoloFrame4") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOFRAME 4");
        } else if (data['data'].action == "downloadYoloFrameXL1") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOFRAME XL 1");
        } else if (data['data'].action == "downloadYoloFrameXL15") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOFRAME XL 15");
        } else if (data['data'].action == "downloadYoloFrameXLH1") {
          console.log("PUSH HANDLE - DOWNLOAD YOLOFRAME XL-H 1");
        }
      }
    }
  }

  imageUrl(country) {
    const eu = ['sk', 'at', 'sl', 'si', 'de', 'be', 'nl', 'dk', 'pl', 'li', 'lu', 'lt', 'sm', 'pt', 'gr', 'it', 'mc', 'lv', 'fr', 'ee', 'se'];
    if (eu.indexOf(country) > -1) {
      if (this.country == 'all') {
        return "https://image.flaticon.com/icons/svg/323/323344.svg";
      } else {
        return "https://flagpedia.net/data/flags/small/" + country + ".png";
      }

    }
    return "https://flagpedia.net/data/flags/small/" + country + ".png";
  }

  selectedType(event)
  {
    console.log(" event:   " + event);
    if (!this.filter.typeArray)
    {
      this.filter.typeArray = [];
    }

    if (event == -20)
    {
      const index = this.filter.typeArray.findIndex((t) => {
        return t == 20;
      });

      console.log("index for 20   " + index);

      if (index == -1)
      {
        this.filter.typeArray.push(20);
      }
      else
      {
        this.filter.typeArray.splice(index, 1);
      }

      console.log(this.filter.typeArray);

      const index2 = this.filter.typeArray.findIndex((t) => {
        return t == 21;
      });

      console.log("  index2    " + index2);

      if (index2 == -1)
      {
        this.filter.typeArray.push(21);
      }
      else
      {
        this.filter.typeArray.splice(index2, 1);
      }

    }
    else
    {

      const index3 = this.filter.typeArray.findIndex((t) => {
        return t == event;
      });

      if (index3 == -1)
      {
        this.filter.typeArray.push(event);
      }
      else
      {
        this.filter.typeArray.splice(index3, 1);
      }

    }
    console.log(this.filter.typeArray);
  }

  selectedStatus(status: any): any {

    const statusIndex = this.filteredStatus.findIndex(
      s => s.value === status.value
    );

    if (statusIndex === -1) {
      this.filteredStatus.push(status);
    } else {
      this.filteredStatus.splice(1, statusIndex)
    }

    this.filter.statusArray = [];
    this.filteredStatus.forEach((s: any) => {
      this.filter.statusArray.push(s.value);
    });

    if (this.filter.statusArray.length === 0) {
      this.filter.statusArray = null;
    }
    console.log('22222222', this.filteredStatus);
  }

  refreshAlbumsOrderState(albums: OrderItem[]) {
    console.log(albums);
    albums.forEach((album) => {
      console.log(this.orders.length);
      const order = this.orders.find(o => o.items.some(i => i.id === album.id));

      console.log(order);

      const item = order.items.find(i => i.id === album.id);
      const indexOfOrder = this.orders.indexOf(order);
      const indexOfItem = order.items.indexOf(item);
      order.items[indexOfItem] = album;

      if (!album.downloading) {
        const index = this.yolobook8itemsToDownload.findIndex(i => i.id === item.id);
        this.yolobook8itemsToDownload.splice(index, 1);
        this.check8();

        const indexXl = this.yolobook8XlitemsToDownload.findIndex(i => i.id === item.id);
        this.yolobook8XlitemsToDownload.splice(indexXl, 1);
        this.check8Xl();

        const indexS = this.yolobookS8itemsToDownload.findIndex(i => i.id === item.id);
        this.yolobookS8itemsToDownload.splice(indexS, 1);
        this.checkS8();

        const indexXlS = this.yolobookS8XlitemsToDownload.findIndex(i => i.id === item.id);
        this.yolobookS8XlitemsToDownload.splice(indexXlS, 1);
        this.checkS8Xl();

        const incexCal = this.yoloCalendarItemsToDownload.findIndex(i => i.id === item.id);
        this.yoloCalendarItemsToDownload.splice(incexCal, 1);
        this.checkCal2();

        const incexCalBasic = this.yoloCalendarBasicItemsToDownload.findIndex(i => i.id === item.id);
        this.yoloCalendarBasicItemsToDownload.splice(incexCalBasic, 1);
        this.checkCal2Basic();

        const incexCalKids = this.yoloCalendarKidsItemsToDownload.findIndex(i => i.id === item.id);
        this.yoloCalendarKidsItemsToDownload.splice(incexCalKids, 1);
        this.checkCal2Kids();

        const indexYoloHardMini = this.yoloHardMini.findIndex(i => i.id === item.id);
        this.yoloHardMini.splice(indexYoloHardMini, 1);
        this.checkHardMini();

        const indexYoloHardMaxi = this.yoloHardMaxi.findIndex(i => i.id === item.id);
        this.yoloHardMaxi.splice(indexYoloHardMaxi, 1);
        this.checkHardMaxi();

        const indexYoloHardMiniS = this.yoloHardMiniS.findIndex(i => i.id === item.id);
        this.yoloHardMiniS.splice(indexYoloHardMiniS, 1);
        this.checkHardMiniS();

        const indexYoloHardMaxiS = this.yoloHardMaxiS.findIndex(i => i.id === item.id);
        this.yoloHardMaxiS.splice(indexYoloHardMaxiS, 1);
        this.checkHardMaxiS();
      }

      let isDownloadingOrder = false;
      order.items.forEach((i) => {
        if (i.downloading)
          isDownloadingOrder = true;
      });

      order.downloading = isDownloadingOrder;

      if (order.status == 3) {
        let isAllPrinted = true;
        order.items.forEach((i) => {
          if (i.status < 4)
            isAllPrinted = false;
        });

        if (isAllPrinted)
          order.status = 4;
      }

      this.orders[indexOfOrder] = order;
    });
  }

  getTranslated(data) {
    return this.translate.get(data).subscribe(
      result => {
        return result;
      }
    )
  }

  checkResolution() {
    const w = window.innerWidth;
    if (w < 450) {
      this.actionOpen = false;
    }
  }

  openMerge() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    let arrayOfIds = '';

    this.selectedOrders.forEach(x => {
      if (x.status === 3 || x.status === 4) {
        if (arrayOfIds == '') {
          arrayOfIds = arrayOfIds + x.id;
        } else {
          arrayOfIds = arrayOfIds + ',' + x.id;
        }
      }
    });

    dialogConfig.data = {'array': arrayOfIds};
    this.mdDialog.open(MergeOrdersComponent, dialogConfig);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  startDownload() {
    this.itemsDownloadRefresh =  this.itemsToDownload

    this.itemsToDownload.forEach((item) => {
      if (item.type === 1) {
        this.getYolobookFile(item);
      } else if (item.type === 4) {
        this.getYolobookFileXl(item);
      } else if (item.type === 2) {
        this.getYoloboxFile(item);
      } else if (item.type === 3) {//frame ima vise proizvoda, gledas po id-u proizvoda
        if (item.product.id === 8)
          this.getFrame1File(item);
        else if (item.product.id === 9)
          this.getFrame4File(item);
        else if (item.product.id === 13)
          this.getFrameXL1File(item);
        else if (item.product.id === 12)
          this.getFrameXL15File(item);
        else if (item.product.id === 10)
          this.getFrameXL1HFile(item);
      } else if (item.type == 5 || item.type == 6 || item.type == 7 || item.type == 8 || item.type == 9 || item.type == 10 || item.type == 11 || item.type == 12) {
        this.getYoloPrints(item);
      } else if (item.type == 20 || item.type == 21) {
        this.getCalendar(item);
      } else if (item.type === 22 || item.type === 36) {
        this.getYoloHardMiniSingle(item);
      } else if (item.type === 23 || item.type === 37 || item.type === 49) {
        this.getYoloHardMaxiSingle(item);
      } else if (item.type === 24) {
        this.getYolobookFileXlS(item);
      } else if (item.type === 25) {
        this.getYolobookSFile(item);
      } else if (item.type === 26 || item.type === 38) {
        this.getYoloHardMiniSingleS(item);
      } else if (item.type === 27 || item.type === 39) {
        this.getYoloHardMaxiSingleS(item);
      } else if (item.type == 30 || item.type == 31) {
        this.getYoloTiles(item);
      } else if (item.type === 35 || item.type === 46) {
        this.getYoloPremium(item);
      } else if (item.type === 40 || item.type === 47) {
        this.getYoloStories(item);
      } else if (item.type === 41 || item.type === 42 || item.type === 43 || item.type === 44 || item.type === 45) {
        this.getYoloCanvas(item);
      }
    });

    this.itemsToDownload = [];
  }

  getYolobook8File(orderItems: OrderItem[]) {
    this.apiService.downloadYoloBook8(orderItems).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYolobook8FileXL(orderItems: OrderItem[]) {
    this.apiService.downloadYolobook8XL(orderItems).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYolobookS8File(orderItems: OrderItem[]) {
    this.apiService.downloadYoloBookS8(orderItems).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYolobook8FileXLS(orderItems: OrderItem[]) {
    this.apiService.downloadYolobook8XLS(orderItems).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getCalendar2(orderItems: OrderItem[]) {
    this.apiService.downloadCalendar2(orderItems).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYolobookFile(orderItem: OrderItem) {
    this.apiService.downloadYolobook(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYolobookSFile(orderItem: OrderItem) {
    this.apiService.downloadYolobookS(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYoloPrints(orderItem: OrderItem) {
    this.apiService.downloadSinglePhoto(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYoloHardMiniSingle(orderItem: any) {
    this.apiService.downloadYoloHardMiniSingle(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYoloHardMaxiSingle(orderItem: any) {
    this.apiService.downloadYoloHardMaxiSingle(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYoloHardMiniSingleS(orderItem: any) {
    this.apiService.downloadYoloHardMiniSingleS(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYoloHardMaxiSingleS(orderItem: any) {
    this.apiService.downloadYoloHardMaxiSingleS(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYoloPremium(orderItem: any) {
    this.apiService.downloadYoloPremium(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYoloStories(orderItem: any) {
    this.apiService.downloadYoloStories(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYoloCanvas(orderItem: any) {
    this.apiService.downloadYoloCanvas(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getCalendar(orderItem: OrderItem) {
    this.apiService.downloadCalendar(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYoloTiles(orderItem: OrderItem) {
    this.apiService.downloadTiles(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }


  getYolobookFileXl(orderItem: OrderItem) {
    this.apiService.downloadYolobookXl(orderItem.id).subscribe((res) => {
      console.log(res);
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getYolobookFileXlS(orderItem: OrderItem) {
    this.apiService.downloadYolobookXlS(orderItem.id).subscribe((res) => {
      console.log(res);
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, (err) => {
      console.log(err);
      this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
    });
  }

  getFrame1File(orderItem: OrderItem) {
    this.apiService.downloadFrame1(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {

    });
  }

  getFrame4File(orderItem: OrderItem) {
    this.apiService.downloadFrame4(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {

    });
  }

  getFrameXL1File(orderItem: OrderItem) {
    this.apiService.downloadFrameXL1(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {

    });
  }

  getFrameXL1HFile(orderItem: OrderItem) {
    this.apiService.downloadFrameXL1H(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {

    });
  }

  getFrameXL15File(orderItem: OrderItem) {
    this.apiService.downloadFrameXL15(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {

    });
  }

  getYoloboxFile(orderItem: OrderItem) {
    this.apiService.downloadYolobox(orderItem.id).subscribe(() => {
      this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
    }, () => {

    });
  }

  getFilStatus(status: any): void {
    console.log('Status', status);
  }

  // this.filteredStatus

  getOrders() {
    this.isLoading = true;
    this.dataLoading = true;
    this.apiService.getOrdersListWithPaging(this.filter, 0).subscribe((data: any) => {
      this.isLoading = false;
      this.dataLoading = false;
      this.orders = data;
      console.log(data);
      this.allDataLoading = false;
    }, (err) => {
      console.log(err);
      this.isLoading = false;
      this.dataLoading = false;
      this.snackBar.open("Greška!", 'OK', {duration: 3000});
    });
    this.apiService.getOrdersInfo(this.filter).subscribe((data: any) => {
      this.infoObj = data.info;
    });
    this.allDataLoading = true;
    this.apiService.getOrdersId(this.filter).subscribe((data: any) => {
      this.ordersData = data.orders;
      console.log('loaded');
      this.allDataLoading = false;
    });
  }

  updateValue(event, cell, rowIndex) {
    // console.log('inline editing rowIndex', cell);
    this.editing[rowIndex + '-' + cell] = false;
    if (cell.indexOf('address') > -1) {
      let cellString = cell.split('.')[1];
      this.orders[rowIndex].address[cellString] = event.target.value;
    } else {
      this.orders[rowIndex][cell] = event.target.value;
    }
    this.orders = [...this.orders];
    // console.log('UPDATED!', this.orders[rowIndex]);
    if (cell !== 'promo_text' && cell != 'price') {
      this.apiService.updateOrder(this.orders[rowIndex]).subscribe((res) => {
        console.log(res);
      }, (err) => {
        this.snackBar.open(err.error.message, "", {duration: 3000});

      });
    } else if (cell === 'promo_text') {
      this.apiService.updateOrderPromo(this.orders[rowIndex]).subscribe((res: OrderList) => {
        this.orders[rowIndex] = res;
        this.orders = [...this.orders];
        this.snackBar.open("Promo kod uspešno dodat!", "", {duration: 2000});
      }, (err) => {
        // console.log(err);
        this.snackBar.open("Greška!", "", {duration: 2000});
      })
    } else if (cell === 'price') {
      this.apiService.updateOrderPrice(this.orders[rowIndex]).subscribe((res: any) => {
        this.orders[rowIndex] = res;
        this.orders = [...this.orders];
        this.snackBar.open(this.translate.instant('priceChanged'), "", {duration: 2000});
      }, (err) => {
        // console.log(err);
        this.snackBar.open(this.translate.instant('thereWasAnError'), "", {duration: 2000});
      })
    }
    this.isEditing = false;
  }

  onSelected(orderId, value) {
    this.apiService.setOrderStatus(orderId, value).subscribe((res) => {
      this.orders.forEach((order: OrderList) => {
        if (order.id == orderId) {
          order.status = value;
        }
      });
    }, (err) => {
      // console.log(err);
      const index = this.orders.findIndex((x) => x.id === orderId);
      this.orders[index].status = value;
    });
  }

  changePaymentType(orderId, value) {
    if (this.user.permissions.find((p) => p.page == 'orders-super')) {
      this.apiService.changePaymentType(orderId, value).subscribe((res) => {
        this.orders.forEach((order: OrderList) => {
          if (order.id == orderId) {
            order.status = value;
          }
        });
      }, (err) => {
        // console.log(err);
        const index = this.orders.findIndex((x) => x.id === orderId);
        this.orders[index].status = value;
      });
    } else {
      this.snackBar.open(this.translate.instant('notAllowed'), 'OK', {duration: 2000});
    }
  }

  getFilterStatusValue() {
    if (this.filter.status)
      return this.statusFilter[this.filter.status - 1].value;
    else
      return null;
  }

  getFilterTypeValue() {
    if (this.filter.itemsType)
      return this.types[this.filter.itemsType - 1].value;
    else
      return null;
  }


  onDownloadClick(order: OrderList): void {
    this.itemsToDownload.push(...order.items);
    // console.log(this.itemsToDownload);
    this.startDownload();
  }

  onDownloadItemClick(item: OrderItem): void {
    this.itemsToDownload.push(item);
    this.startDownload();
  }

  getRowClass(row): any {

    let nameClass = "";

    if (row.updated) {
      if (row.status == 10)
        return 'failed-updated';
      if (row.status == 1)
        return 'upload-updated';
      else if (row.status == 2)
        return 'wait-updated';
      else if (row.status == 3)
        return 'proccesed-updated';
      else if (row.status == 4)
        return 'print-updated';
      else if (row.status == 5)
        return 'sent-updated';
    }

    if (row.payingStatus == 5)
      return 'failed'

    if (row.is_fast == 1) {
      if (row.status == 10)
        return 'failed fast';
      if (row.status == 1)
        return 'upload fast';
      else if (row.status == 2)
        return 'wait fast';
      else if (row.status == 3)
        return 'proccesed fast';
      else if (row.status == 4)
        return 'print fast';
      else if (row.status == 5)
        return 'sent fast';

    } else {

      if (row.status == 10)
        return 'failed';
      if (row.status == 1)
        return 'upload';
      else if (row.status == 2)
        return 'wait';
      else if (row.status == 3)
        return 'proccesed';
      else if (row.status == 4)
        return 'print';
      else if (row.status == 5)
        return 'sent';
    }
  }

  getFast(is_fast) {
    if (is_fast == 1)
      return 'Brza pošiljka';
    else
      return '';
  }

  onFreeChanged(order: Order, $event) {
    let free = 0;
    if ($event.checked) {
      free = 1;
    }

    this.apiService.toggleOrderFree(order.id, free).subscribe((order: OrderList) => {
      const index = this.orders.findIndex((x) => x.id == order.id);
      this.orders[index] = order;
      this.orders = [...this.orders];

    });

  }

  onSendingsExtraChanged(order: Order, $event: any) {
    this.apiService.setSendingsExtra(order.id).subscribe((result: any) => {
      if (result) {
        console.log(result.price);
        const index = this.orders.findIndex((o) => o.id == result.id);
        if (index != -1) {
          this.orders[index].price = result.price;
          this.orders = [...this.orders];
        }
      }
    });
  }

  onFastChanged(order: Order, $event) {
    if ($event.checked) {
      this.apiService.setFastOrder(order.id, 1).subscribe((result) => {
        console.log(result);
      });
    } else {
      this.apiService.setFastOrder(order.id, 0).subscribe((result) => {
        console.log(result);
      });
    }

  }

  onGiftChanged(order: Order, $event) {
    if ($event.checked) {
      this.apiService.setGiftOrder(order.id, 1).subscribe((result) => {
        console.log(result);
      });
    } else {
      this.apiService.setGiftOrder(order.id, 0).subscribe((result) => {
        console.log(result);
      });
    }

  }

  onDeleteClick(id, row: any) {
    if (row.status === 5) {
      this.snackBar.open(this.translate.instant('CancelRemove'), 'OK');
    } else {
      let dialogRef: MatDialogRef<ConfirmComponent>;
      dialogRef = this.mdDialog.open(ConfirmComponent);
      dialogRef.componentInstance.title = 'OBRIŠI';
      dialogRef.componentInstance.message = 'Da li ste sigurni da želite da obrišete porudzbinu #' + id + '?';
      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          let i = 0;
          this.orders.forEach((order: OrderList, index) => {
            if (order.id == id) {
              i = index;
            }
          });

          this.orders.splice(i, 1);

          this.apiService.deleteOrder(id).subscribe(() => {
            this.getOrders();
            this.snackBar.open('Narudžbina obrisana.', '', {
              duration: 1000
            });
          }, (error) => {
            this.snackBar.open('GREŠKA, prilikom brisanja albuma!', '', {
              duration: 2000
            });
            console.log(error);
          });
        } else {
          console.log('null');
        }
      });
    }
  }

  trackByFn(index, order) {
    return order.id;
  }

  onTrackingClick(orderId: number) {
    let dialogRef: MatDialogRef<InfoComponent>;
    dialogRef = this.mdDialog.open(InfoComponent);
    dialogRef.componentInstance.orderId = orderId;

  }

  onDownloadByIdClick() {
    let dialogRef: MatDialogRef<DownloadByIdComponent>;
    dialogRef = this.mdDialog.open(DownloadByIdComponent);
    dialogRef.afterClosed().subscribe((ids) => {
      if (ids != "") {
        this.apiService.getAlbumsByIds(ids).subscribe((albums: any) => {
          this.getYolobook8File(albums);
        });
      }
    });

  }

  onDownloadByIdClickXl() {
    let dialogRef: MatDialogRef<DownloadById6Component>;
    dialogRef = this.mdDialog.open(DownloadById6Component);
    dialogRef.afterClosed().subscribe((ids) => {
      if (ids != "") {
        this.apiService.getAlbumsByIds(ids).subscribe((albums: any) => {
          this.getYolobook8FileXL(albums);
        });
      }
    });

  }

  onDownloadByIdClickS() {
    let dialogRef: MatDialogRef<DownloadByIdComponent>;
    dialogRef = this.mdDialog.open(DownloadByIdComponent);
    dialogRef.afterClosed().subscribe((ids) => {
      if (ids != "") {
        this.apiService.getAlbumsByIds(ids).subscribe((albums: any) => {
          this.getYolobookS8File(albums);
        });
      }
    });

  }

  onDownloadByIdClickXlS() {
    let dialogRef: MatDialogRef<DownloadById6Component>;
    dialogRef = this.mdDialog.open(DownloadById6Component);
    dialogRef.afterClosed().subscribe((ids) => {
      if (ids != "") {
        this.apiService.getAlbumsByIds(ids).subscribe((albums: any) => {
          this.getYolobook8FileXLS(albums);
        });
      }
    });

  }


  onCustomsClick() {
    let dialogRef: MatDialogRef<CustomsComponent>;
    dialogRef = this.mdDialog.open(CustomsComponent);
    dialogRef.componentInstance.bookPrice = this.getCustomsBookPrice();
    dialogRef.componentInstance.bookXLPrice = this.getCustomsBookPriceXL();
    dialogRef.componentInstance.boxPrice = this.getCustomsBoxPrice();
    dialogRef.componentInstance.framePrice = this.getCustomsFramePrice();
    dialogRef.componentInstance.frameXLPrice = this.getCustomsFrameXLPrice();
    dialogRef.componentInstance.printsPrice = this.getCustomsPrintsPrice();

    dialogRef.componentInstance.bookWeight = this.getCustomsBookWeight();
    dialogRef.componentInstance.bookXLWeight = this.getCustomsBookWeightXL();
    dialogRef.componentInstance.boxWeight = this.getCustomsBoxWeight();
    dialogRef.componentInstance.frameWeight = this.getCustomsFrameWeight();
    dialogRef.componentInstance.frameXLWeight = this.getCustomsFrameXLWeight();
    dialogRef.componentInstance.printsWeight = this.getCustomsPrintsWeight();
    dialogRef.componentInstance.calendarWeight = this.getCustomsCalendarWeight();
    dialogRef.componentInstance.calendarPrice = this.getCustomsCalendarPrice();
    dialogRef.componentInstance.hardMiniPrice = this.infoObj['hardMiniPrice'];
    dialogRef.componentInstance.hardMaxiPrice = this.infoObj['hardMaxiPrice'];
    dialogRef.componentInstance.hardMiniWeight = this.infoObj['hardMiniWeight'];
    dialogRef.componentInstance.hardMaxiWeight = this.infoObj['hardMaxiWeight'];
    dialogRef.componentInstance.hardMiniSPrice = this.infoObj['hardMiniSPrice'];
    dialogRef.componentInstance.hardMaxiSPrice = this.infoObj['hardMaxiSPrice'];
    dialogRef.componentInstance.hardMiniSWeight = this.infoObj['hardMiniSWeight'];
    dialogRef.componentInstance.hardMaxiSWeight = this.infoObj['hardMaxiSWeight'];
    dialogRef.componentInstance.tilesSquareWeight = this.infoObj['tilesSquareWeight'];
    dialogRef.componentInstance.tilesSquarePrice = this.infoObj['tilesSquarePrice'];
    dialogRef.componentInstance.tilesHexWeight = this.infoObj['tilesHexWeight'];
    dialogRef.componentInstance.tilesHexPrice = this.infoObj['tilesHexPrice'];

    console.log(dialogRef.componentInstance);
  }

  onSearch($event) {
    this.isLoading = true;
    this.filter.firstView = null;
    this.getOrders();
  }

  onReset($event) {
    this.filter = {
      id: null,
      status: null,
      name: '',
      email: "",
      counts: null,
      fromId: null,
      isFast: null,
      isFree: null,
      phone: "",
      promo_text: "",
      toDate: "",
      toId: null,
      fromDate: "",
      firstView: 1,
      isPromo: null,
      album: null,
      itemsType: null,
      price: null,
      device: null,
      payingMethod: null,
      payingStatus: null,
      giftPackage: null
    };
    this.fromInput.value = '';
    this.toInput.value = '';
    this.getOrders();
  }

  onRefresh($event) {
    this.getOrders();
  }

  //INFO DUMMY METHODS

  getAlbumCount() {
    return this.infoObj['albumCount'];
  }

  getOrderCount() {
    return this.infoObj['orderCount'];
  }

  getBookCounts() {
    return this.infoObj['bookCount'];
  }

  getBookXLCounts() {
    return this.infoObj['bookXlCount'];
  }

  getPrintsCounts() {
    return this.infoObj['printsCount'];
  }

  getCalendarCounts() {
    return this.infoObj['calendarsCount'];
  }

  getBoxCounts() {
    return this.infoObj['boxCount'];
  }

  getFrameCounts() {
    return this.infoObj['frameCount'];
  }

  getFrameXLCounts() {
    return this.infoObj['frameXLCount'];
  }


  getCustomsBookPrice() {
    return this.infoObj['customsBookPrice'];
  }

  getCustomsBookPriceXL() {
    return this.infoObj['customsBookXlPrice'];
  }

  getCustomsBookWeight() {
    return this.infoObj['customsBookWeight'];
  }

  getCustomsBookWeightXL() {
    return this.infoObj['customsBookXLWeight'];
  }

  getCustomsBoxPrice() {
    console.log(this.infoObj);
    return this.infoObj['customsBoxPrice'];
  }

  getCustomsBoxWeight() {
    return this.infoObj['customsBoxWeight'];
  }

  getCustomsFramePrice() {
    return this.infoObj['customsFramePrice'];
  }

  getCustomsFrameWeight() {
    return this.infoObj['customsFrameWeight'];

  }

  getCustomsFrameXLPrice() {
    return this.infoObj['customsFrameXLPrice'];
  }

  getCustomsFrameXLWeight() {
    return this.infoObj['customsFrameXLWeight'];
  }

  getCustomsPrintsPrice() {
    return this.infoObj['customsYoloPrintsPrice'];
  }

  getCustomsCalendarPrice() {
    return this.infoObj['calendarPrice'];
  }

  getCustomsCalendarWeight() {
    return this.infoObj['calendarWeight'];
  }

  getCustomsPrintsWeight() {
    return this.infoObj['customsYoloPrintsWeight'];
  }

  getFastAlbumsCounts() {
    let count = 0;
    this.orders.forEach((order) => {
      if (order.is_fast == 1)
        count = count + order.count;
    });

    return count;
  }

  getFastCounts() {
    let count = 0;
    this.orders.forEach((order) => {
      if (order.is_fast == 1)
        count = count + 1;
    });

    return count;
  }

  onAddPromoCode(order: Order) {
    console.log(order);
  }

  onDownloadYoloBook8() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YOLOBOOK';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloBook Mini (po 8)?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.getYolobook8File(this.yolobook8itemsToDownload);
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloBookS8() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YOLOBOOK';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloBook Mini S (po 8)?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.getYolobookS8File(this.yolobookS8itemsToDownload);
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloBook8Xl() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YOLOBOOK';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloBook Maxi (po 6)?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.getYolobook8FileXL(this.yolobook8XlitemsToDownload);
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloBook8XlS() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YOLOBOOK';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloBook Maxi S (po 6)?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.getYolobook8FileXLS(this.yolobookS8XlitemsToDownload);
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloCalendar2() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI CALENDAR';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve Calendar-e (po 2)?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.getCalendar2(this.yoloCalendarItemsToDownload);
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloCalendar2Basic() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI CALENDAR BASIC';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve Basic Calendar-e (po 2)?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.getCalendar2(this.yoloCalendarBasicItemsToDownload);
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloCalendar2Kids() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI CALENDAR KIDS';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve Kids Calendar-e (po 2)?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.getCalendar2(this.yoloCalendarKidsItemsToDownload);
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloBook() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YOLOBOOK';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete YoloBook Mini?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 1)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloBookXl() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YOLOBOOK';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloBook Maxi?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 4)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloBookS() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YOLOBOOK';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloBook Mini S?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 1)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloBookXlS() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YOLOBOOK';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloBook Maxi S?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 4)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloBox() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YOLOBOX';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloBox-ove?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status === 3) {
            order.items.forEach((item) => {
              if (item.type === 2)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloTilesSquare() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI Yolo Tiles Square';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve Yolo Tiles Square?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 30)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloCanvas() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI Yolo Canvas';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve Yolo Canvas-e?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 41 || item.type == 42 || item.type == 43 || item.type == 44 || item.type == 45)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloTilesHex() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI Yolo Tiles Hexagon';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve Yolo Tiles Hexagon?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 31)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloCalendar() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloCalendar';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloCalendar-e?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 20 || item.type == 21)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloPremium() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloCalendar';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloPremium-e?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 35 || item.type == 46)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloHardMini(): void {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloHard Mini';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloHard Mini-je (po 2)?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        // console.log('MNI', this.yoloHardMini);
        this.yoloHardMini.forEach(item => {
          if (item.type === 22 || item.type == 36)
            this.miniId.push(item.id);
        });
        this.downloadToService('YoloHardMini', this.miniId);
        this.miniId = [];
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloStories(): void {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloHard Mini';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloStories (po 2)?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        // console.log('MNI', this.yoloHardMini);
        this.yoloStories.forEach(item => {
          if (item.type === 40 || item.type === 47)
            this.storiesId.push(item.id);
        });
        this.downloadToService('YoloStories', this.storiesId);
        this.storiesId = [];
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloHardMaxi(): void {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloHard Maxi';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloHard Maxi-je (po 2)?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.yoloHardMaxi.forEach(item => {
          if (item.type === 23 || item.type === 37 || item.type === 49)
            this.maxiId.push(item.id);
        });
        this.downloadToService('YoloHardMaxi', this.maxiId);
        this.maxiId = [];
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloHardMiniS(): void {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloHard Square S';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloHard Square S (po 2)?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        // console.log('MNI', this.yoloHardMini);
        this.yoloHardMiniS.forEach(item => {
          if (item.type === 26 || item.type == 38)
            this.miniSId.push(item.id);
        });
        this.downloadToService('YoloHardMiniS', this.miniSId);
        this.miniSId = [];
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloHardMaxiS(): void {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloHard Landscape S';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloHard Landscape S (po 2)?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.yoloHardMaxiS.forEach(item => {
          if (item.type === 27 || item.type === 39)
            this.maxiSId.push(item.id);
        });
        this.downloadToService('YoloHardMaxiS', this.maxiSId);
        this.maxiSId = [];
      } else {
        console.log('null');
      }
    });
  }

  downloadToService(mode: string, orders: any): void {
    if (mode === 'YoloHardMini') {
      this.apiService.downloadYoloHardMiniGroup(orders).subscribe(() => {
        this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
      }, () => {
        this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
      });
    } else if (mode === 'YoloHardMaxi') {
      this.apiService.downloadYoloHardMaxiGroup(orders).subscribe(() => {
        this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
      }, () => {
        this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
      });
    } else if (mode === 'YoloHardMaxiS') {
      this.apiService.downloadYoloHardMaxiSGroup(orders).subscribe(() => {
        this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
      }, () => {
        this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
      });
    } else if (mode === 'YoloHardMiniS') {
      this.apiService.downloadYoloHardMiniSGroup(orders).subscribe(() => {
        this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
      }, () => {
        this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
      });
    } else if (mode === 'YoloStories') {
      this.apiService.downloadYoloStoriesGroup(orders).subscribe(() => {
        this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
      }, () => {
        this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
      });
    }
    else if (mode === 'HCL Partizan') {
      this.apiService.downloadYoloStoriesGroup(orders).subscribe(() => {
        this.snackBar.open(this.translate.instant('album8Notification'), 'OK');
      }, () => {
        this.snackBar.open(this.translate.instant('unsuccessfulDownload'));
      });
    }
  }

  onDownloadYoloCalendarBasic() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloCalendar';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloCalendar-e?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status === 3) {
            order.items.forEach((item) => {
              if (item.type === 20)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloCalendarKids() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloCalendar';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloCalendar-e?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 21)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloVintage() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloVintage';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloVintage?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 6)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloSquareMini() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloSquare Mini';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloSquare Mini?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 7)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloClassic() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloClassic';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloClassic?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 8)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloOriginal() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloOriginal';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloOriginal?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 9)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloSquare() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloSquare ';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloSquare?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 12)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }


  onDownloadYoloRegular() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloRegular ';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloRegular?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 11)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloSquareMaxi() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YoloSquare Maxi ';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloSquare Maxi?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 10)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onDownloadYoloFrame() {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'SKINI YOLOFRAME';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete sve YoloFrame-ove?';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ordersData.forEach((order) => {
          if (order.status == 3) {
            order.items.forEach((item) => {
              if (item.type == 3)
                this.itemsToDownload.push(item);
            });
            this.startDownload();
          }
        });
      } else {
        console.log('null');
      }
    });
  }

  onExcelUpload($event) {
    const file = $event.target.files[0];
    const formData = new FormData();
    formData.append('excell', file);

    const url = environment.url + '/panel/excell/import/' + this.country;
    this.apiService.uploadTracking(formData, this.country).subscribe(() => {
    }, () => {
      this.snackBar.open(this.translate.instant('thereWasAnError'), '', {duration: 2000});
    });
    this.snackBar.open(this.translate.instant('trackingForNotification'), '', {duration: 2000});
  }

  onExcell() {
    this.apiService.getOrdersId(this.filter).subscribe((data: any) => {
        this.ordersData = data.orders;
    });
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'EXCEL';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da skinete excel fajl? ';
    dialogRef.componentInstance.button = 'Skini';
    dialogRef.componentInstance.country = this.country;
    dialogRef.afterClosed().subscribe((confirm) => {
      console.log(
        '1', this.yolobook8itemsToDownload,
        '2', this.yolobook8XlitemsToDownload,
        '3', this.yoloCalendarItemsToDownload,
        '4', this.yoloCalendarBasicItemsToDownload,
        '5', this.yoloCalendarKidsItemsToDownload,
        '6', this.yoloHardMini,
        '7', this.yoloHardMaxi);
      if (confirm) {
        if (this.yolobook8itemsToDownload.length > 0) {
          this.yolobook8itemsToDownload.forEach(item => {
            if (item.status === 4) {
              this.yoloBookSendings.push(item.id);
            }
          });
          const pickupDate = localStorage.getItem('pickupDate');

          if(localStorage.getItem('country') === 'ba')
          {
            this.getSendingsBa(this.yoloBookSendings)
          }
          else if(localStorage.getItem('country') === 'ro')
          {
            this.getSendingsRo(this.yoloBookSendings)
          }
          else
          {
            this.getSending(this.yoloBookSendings, pickupDate);
          }

        } else {
          this.dataLoading = true;
          const pickupDate = localStorage.getItem('pickupDate');
          console.log('dateFromOrders   ' + pickupDate);
          const ids: number[] = [];
          this.ordersData.forEach((order) => {
            if (order.status == 4) {
              ids.push(order.id);
              this.orders.forEach((order2) => {
                if (order.id == order2.id) {
                  order2.status = 5;
                }
              });
            }
          });
          console.log('ids  ' + ids);

          if(localStorage.getItem('country') === 'ba')
          {
            console.log("Bosnia");
            this.getSendingsBa(ids)
          }
          else if(localStorage.getItem('country') === 'ro')
          {
            console.log("Romania");
            this.getSendingsRo(ids)
          }
          else
          {
            this.getSending(ids, pickupDate);
          }

        }
      } else {
        this.yoloBookSendings = [];
        console.log('null');
      }
    });
  }

  exportOrders() {
    this.snackBar.open(this.translate.instant("downloadStarted"), "OK");
    this.apiService.getOrdersId(this.filter).subscribe((data: any) => {
      this.ordersData = data.orders;
      const ids = this.ordersData.map(o => o.id);
      const body = {ids, collumns: this.collums, country: this.country};

      this.apiService.exportOrders(body, this.country).subscribe((res) => {
          this.isLoading = false;
          this.dataLoading = false;
          const filename = new Date().getMonth() + "." + new Date().getDate() + '._' +
            new Date().getHours() + "." + new Date().getMinutes() + "_orders.xlsx";
          saveAs(res, filename);
        },
        () => {
          this.isLoading = false;
          this.dataLoading = false;
          this.snackBar.open(this.translate.instant("exportOrders") + this.translate.instant("thereWasAnError"), "OK");
          this.yoloBookSendings = [];
        });
    });
  }

  onSendInvoice() {
    this.apiService.getOrdersId(this.filter).subscribe((data: any) => {
      this.ordersData = data.orders;
    });
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.mdDialog.open(ConfirmComponent);
    dialogRef.componentInstance.title = 'EXCEL';
    dialogRef.componentInstance.message = 'Da li ste sigurni da želite da pošaljete račune? ';
    dialogRef.componentInstance.button = 'Pošalji';
    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        if (this.yolobook8itemsToDownload.length > 0) {
          this.yolobook8itemsToDownload.forEach(item => {
            if (item.status === 5) {
              this.yoloBookSendings.push(item.id);
            }
          });
          const pickupDate = localStorage.getItem('pickupDate');
          this.getSending(this.yoloBookSendings, pickupDate);

        } else {
          this.dataLoading = true;
          const pickupDate = localStorage.getItem('pickupDate');
          console.log('dateFromOrders   ' + pickupDate);
          const ids: number[] = [];
          this.ordersData.forEach((order) => {
            if (order.status == 5) {
              ids.push(order.id);
              this.orders.forEach((order2) => {
                if (order.id == order2.id) {
                  order2.status = 5;
                }
              });
            }
          });
          console.log('ids  ' + ids);
          this.sendInvoices(ids);
        }
      } else {
        this.yoloBookSendings = [];
        console.log('null');
      }
    });
  }


  getSending(orders: number[], pickupDate: any): void {
    this.apiService.downloadXls(orders, pickupDate).subscribe(() => {
        this.isLoading = false;
        this.dataLoading = false;
        this.snackBar.open(this.translate.instant("sendingsNotification"), "OK");
        this.yoloBookSendings = [];
      },
      () => {
        this.isLoading = false;
        this.dataLoading = false;
        this.snackBar.open(this.translate.instant("thereWasAnError"), "OK");
        this.yoloBookSendings = [];
      });
  }

  getSendingsBa(orders: number[])
  {
    this.apiService.downloadPdfBa(orders).subscribe(res => {
        this.isLoading = false;
        this.dataLoading = false;
        this.yoloBookSendings = [];
        this.snackBar.open(this.translate.instant("successSending"), "OK");
        saveAs(res, 'sendings_bosnia_' + new Date().toISOString() + '.zip');
      },
      err => {
        this.isLoading = false;
        this.dataLoading = false;
        const errorMessage = err.error && err.error.error ? err.error.error : this.translate.instant("thereWasAnError");
        this.snackBar.open(errorMessage, "OK");
        this.yoloBookSendings = [];
      });
  }

  getSendingsRo(orders: number[])
  {
    this.apiService.downloadPdfRo(orders).subscribe(res => {
        this.isLoading = false;
        this.dataLoading = false;
        this.yoloBookSendings = [];
        this.snackBar.open(this.translate.instant("successSending"), "OK");
      },
      err => {
        this.isLoading = false;
        this.dataLoading = false;
        const errorMessage = err.error && err.error.error ? err.error.error : this.translate.instant("thereWasAnError");
        this.snackBar.open(errorMessage, "OK");
        this.yoloBookSendings = [];
      });
  }

  sendInvoices(orders: number[]): void {
    this.apiService.sendInvoices(orders).subscribe(() => {
        this.isLoading = false;
        this.dataLoading = false;
        this.snackBar.open(this.translate.instant("invoicesSent"), "OK");
        this.yoloBookSendings = [];
      },
      () => {
        this.isLoading = false;
        this.dataLoading = false;
        this.snackBar.open(this.translate.instant("thereWasAnError"), "OK");
        this.yoloBookSendings = [];
      });
  }

  public downloadXls(ids): any {
    let url = environment.url + '/panel/excell/new/print';
    console.log('ids   ' + JSON.stringify(ids));
    return this.http.post(url, ids, {
      headers: new HttpHeaders({'Country': localStorage.getItem("country")}),
      responseType: "blob"
    }).map(
      (res) => {
        return new Blob([res], {type: 'application/xls'});
      });
  }

  onAppChecked($event) {
    if ($event.checked) {
      this.collums.appVersion = true;
    } else {
      this.collums.appVersion = false;
    }
  }

  toggleExpandRow(row) {
    // console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    this.expandedRows.push(event.value.id);
  }

  onActivated($event) {
  }

  getAllOrders() {
    this.isLoading = true;
    this.dataLoading = true;
    this.apiService.getOrdersList(this.filter).subscribe(
      (ordersRes: any) => {
        this.isLoading = false;
        this.dataLoading = false;
        this.orders = [...ordersRes];
        this.noMoreData = true;
      });
  }

  onSelect($event: any) {

    this.selectedOrders = [];


    $event.selected.forEach((order: OrderList) => {
      // Stiklirati 3 => obradjene; 4 => stampa se; 5 => poslate ;
      // if (order.status === 3 || order.status === 4 || order.status === 5)

      // Stiklirati samo obradjene
      if (order.status === 3 || order.status === 4 || order.status === 5)
        this.selectedOrders.push(order);
    });

    // console.log('Selected', this.selectedOrders);

    this.yolobook8itemsToDownload = [];
    this.yolobook8XlitemsToDownload = [];
    this.yolobookS8itemsToDownload = [];
    this.yolobookS8XlitemsToDownload = [];
    this.yoloCalendarItemsToDownload = [];
    this.yoloCalendarBasicItemsToDownload = [];
    this.yoloCalendarKidsItemsToDownload = [];
    this.yoloHardMini = [];
    this.yoloHardMaxi = [];
    this.yoloHardMiniS = [];
    this.yoloHardMaxiS = [];
    this.yoloStories = [];

    console.log('Mini praznjenje', this.yoloHardMini);
    this.selectedOrders.forEach((order: OrderList) => {
      if (order.status === 3 || order.status === 4 || order.status === 5) {

        order.items.forEach((item: OrderItem) => {

          if (item.type === 1 && (item.status === 3 || item.status === 4 || item.status === 5)) {
            const idx = this.yolobook8itemsToDownload.findIndex(x => x.id === item.id);

            if (idx === -1)
              this.yolobook8itemsToDownload.push(item);

          } else if (item.type === 4 && (item.status === 3 || item.status === 4 || item.status === 5)) {
            const idx = this.yolobook8XlitemsToDownload.findIndex(x => x.id === item.id);

            if (idx === -1)
              this.yolobook8XlitemsToDownload.push(item);

          } else if (item.type === 24 && (item.status === 3 || item.status === 4 || item.status === 5)) {
            const idx = this.yolobookS8XlitemsToDownload.findIndex(x => x.id === item.id);

            if (idx === -1)
              this.yolobookS8XlitemsToDownload.push(item);

          } else if (item.type === 25 && (item.status === 3 || item.status === 4 || item.status === 5)) {
            const idx = this.yolobookS8itemsToDownload.findIndex(x => x.id === item.id);

            if (idx === -1)
              this.yolobookS8itemsToDownload.push(item);

          } else if ((item.type === 20 || item.type === 21) && (item.status === 3 || item.status === 4 || item.status === 5)) {
            const idcal = this.yoloCalendarItemsToDownload.findIndex((x) => x.id == item.id);

            if (idcal === -1) {
              this.yoloCalendarItemsToDownload.push(item);
            }

            if (item.type === 20) {
              const idcalb = this.yoloCalendarBasicItemsToDownload.findIndex((x) => x.id === item.id);

              if (idcalb === -1) {
                this.yoloCalendarBasicItemsToDownload.push(item);
              }

            } else if (item.type === 21) {
              const idcalk = this.yoloCalendarKidsItemsToDownload.findIndex((x) => x.id === item.id);

              if (idcalk === -1) {
                this.yoloCalendarKidsItemsToDownload.push(item);
              }
            }
          }

          if (item.type === 22 || item.type === 36) {
            const idHCMani = this.yoloHardMini.findIndex((x) => x.id === item.id);

            if (idHCMani === -1) {
              this.yoloHardMini.push(item);
            }
          }

          if (item.type === 23 || item.type === 37 || item.type === 49) {
            const idHCMaxi = this.yoloHardMaxi.findIndex((x) => x.id === item.id);

            if (idHCMaxi === -1) {
              this.yoloHardMaxi.push(item);
            }
          }

          if (item.type === 27 || item.type === 39) {
            const idHCMani = this.yoloHardMaxiS.findIndex((x) => x.id === item.id);

            if (idHCMani === -1) {
              this.yoloHardMaxiS.push(item);
            }
          }

          if (item.type === 26 || item.type === 38) {
            const idHCMani = this.yoloHardMiniS.findIndex((x) => x.id === item.id);

            if (idHCMani === -1) {
              this.yoloHardMiniS.push(item);
            }
          }


          if (item.type === 40 || item.type === 47) {
            const idHCMani = this.yoloStories.findIndex((x) => x.id === item.id);

            if (idHCMani === -1) {
              this.yoloStories.push(item);
            }
          }


        });
      }
    });

    this.check8();
    this.check8Xl();
    this.checkS8();
    this.checkS8Xl();
    this.checkCal2();
    this.checkCal2Kids();
    this.checkCal2Basic();
    this.checkHardMini();
    this.checkHardMaxi();
    this.checkHardMiniS();
    this.checkHardMaxiS();
    this.checkYoloStories();
  }

  checkDisabledItemYolobook(item: OrderItem) {
    if (item.type == 1 || item.type == 4 || item.status == 3 || item.status == 4 || item.status == 5)
      return false;
    else
      return true;
  }

  onItemSelect(item: OrderItem, order: OrderList, $event) {
    //Yolo
    if (item.type == 1 && (item.status == 3 || item.status == 4 || item.status == 5)) {
      // Provera proizvoda po statusu porudzbine
      // status 3 = processed; status 4 = printing; status 5 = sent;

      const idx = this.yolobook8itemsToDownload.findIndex(x => x.id == item.id);
      // yolobook8itemsToDownload = yoloBook Mini
      if (idx === -1) {
        this.yolobook8itemsToDownload.push(item);

      } else if (idx !== -1) {
        this.yolobook8itemsToDownload.splice(idx, 1);

        if (this.yolobook8itemsToDownload) {
          this.ybDiff = -1;
        }
      }
    }
    //Yolo Maxi S
    if (item.type == 24 && (item.status == 3 || item.status == 4 || item.status == 5)) {
      // Provera proizvoda po statusu porudzbine
      // status 3 = processed; status 4 = printing; status 5 = sent;

      const idx = this.yolobookS8XlitemsToDownload.findIndex(x => x.id == item.id);
      // yolobook8itemsToDownload = yoloBook Mini
      if (idx === -1) {
        this.yolobookS8XlitemsToDownload.push(item);

      } else if (idx !== -1) {
        this.yolobookS8XlitemsToDownload.splice(idx, 1);

        if (this.yolobookS8XlitemsToDownload) {
          this.ybDiff = -1;
        }
      }
    }
    //Yolo Mini S
    if (item.type == 25 && (item.status == 3 || item.status == 4 || item.status == 5)) {
      // Provera proizvoda po statusu porudzbine
      // status 3 = processed; status 4 = printing; status 5 = sent;

      const idx = this.yolobookS8itemsToDownload.findIndex(x => x.id == item.id);
      // yolobook8itemsToDownload = yoloBook Mini
      if (idx === -1) {
        this.yolobookS8itemsToDownload.push(item);

      } else if (idx !== -1) {
        this.yolobookS8itemsToDownload.splice(idx, 1);

        if (this.yolobookS8itemsToDownload) {
          this.ybDiff = -1;
        }
      }
    }
    //Calendars
    if ($event.checked == true && (item.type === 20 || item.type === 21) && (item.status == 3 || item.status == 4 || item.status == 5)) {
      const idx = this.yoloCalendarItemsToDownload.findIndex(x => x.id == item.id);

      if (idx > -1)
        this.yoloCalendarItemsToDownload.splice(idx, 1);
      this.yoloCalendarItemsToDownload.push(item);

      if (item.type == 20) {
        const idcalb = this.yoloCalendarBasicItemsToDownload.findIndex((x) => x.id == item.id);

        if (idcalb == -1) {
          this.yoloCalendarBasicItemsToDownload.push(item);
        }

      } else if (item.type == 21) {
        const idcalk = this.yoloCalendarKidsItemsToDownload.findIndex((x) => x.id == item.id);

        if (idcalk == -1) {
          this.yoloCalendarKidsItemsToDownload.push(item);
        }
      }
    }
    //Yolo Maxi
    if (item.type == 4 && (item.status == 3 || item.status == 4 || item.status == 5)) {
      const idx = this.yolobook8XlitemsToDownload.findIndex(x => x.id == item.id);

      if (idx === -1) {
        this.yolobook8XlitemsToDownload.push(item);
      } else if (idx !== -1) {
        this.yolobook8XlitemsToDownload.splice(idx, 1);
      }
    }
    //Yolo Hard Maxi S
    if ((item.type == 27 || item.type == 39) && (item.status == 3 || item.status == 4 || item.status == 5)) {

      const idx = this.yoloHardMaxiS.findIndex(x => x.id == item.id);
      // yolobook8itemsToDownload = yoloBook Mini
      if (idx === -1) {
        this.yoloHardMaxiS.push(item);

      } else if (idx !== -1) {
        this.yoloHardMaxiS.splice(idx, 1);

        if (this.yoloHardMaxiS) {
          this.ybDiff = -1;
        }
      }
    }
    //Yolo Hard Mini S
    if ((item.type == 26 || item.type == 38) && (item.status == 3 || item.status == 4 || item.status == 5)) {

      const idx = this.yoloHardMiniS.findIndex(x => x.id == item.id);
      // yolobook8itemsToDownload = yoloBook Mini
      if (idx === -1) {
        this.yoloHardMiniS.push(item);

      } else if (idx !== -1) {
        this.yoloHardMiniS.splice(idx, 1);

        if (this.yoloHardMiniS) {
          this.ybDiff = -1;
        }
      }
    }
    //Yolo Hard Maxi
    if ((item.type == 23 || item.type == 37 || item.type == 49) && (item.status == 3 || item.status == 4 || item.status == 5)) {
      // Provera proizvoda po statusu porudzbine
      // status 3 = processed; status 4 = printing; status 5 = sent;

      const idx = this.yoloHardMaxi.findIndex(x => x.id == item.id);
      // yolobook8itemsToDownload = yoloBook Mini
      if (idx === -1) {
        this.yoloHardMaxi.push(item);

      } else if (idx !== -1) {
        this.yoloHardMaxi.splice(idx, 1);

        if (this.yoloHardMaxi) {
          this.ybDiff = -1;
        }
      }
    }
    //Yolo Hard Mini
    if ((item.type == 22 || item.type == 36) && (item.status == 3 || item.status == 4 || item.status == 5)) {
      // Provera proizvoda po statusu porudzbine
      // status 3 = processed; status 4 = printing; status 5 = sent;

      const idx = this.yoloHardMini.findIndex(x => x.id == item.id);
      // yolobook8itemsToDownload = yoloBook Mini
      if (idx === -1) {
        this.yoloHardMini.push(item);

      } else if (idx !== -1) {
        this.yoloHardMini.splice(idx, 1);

        if (this.yoloHardMini) {
          this.ybDiff = -1;
        }
      }
    }

    if ((item.type === 40 || item.type === 47) && (item.status == 3 || item.status == 4 || item.status == 5)) {
      const idHCMani = this.yoloStories.findIndex((x) => x.id === item.id);

      if (idHCMani === -1) {
        this.yoloStories.push(item);
      }
    }


    this.check8Xl();
    this.check8();
    this.checkS8Xl();
    this.checkS8();
    this.checkCal2();
    this.checkHardMini();
    this.checkHardMaxi();
    this.checkHardMaxiS();
    this.checkHardMiniS();
    this.checkYoloStories();
  }

  getIfItemIsChecked(item: OrderItem) {
    if (item.type == 1) {
      const idx = this.yolobook8itemsToDownload.findIndex(x => x.id == item.id);

      if (idx > -1) {
        return true;
      }
      return false;
    } else if (item.type == 4) {
      const idx = this.yolobook8XlitemsToDownload.findIndex(x => x.id == item.id);

      if (idx > -1) {
        return true;
      }
    } else if (item.type == 24) {
      const idx = this.yolobookS8XlitemsToDownload.findIndex(x => x.id == item.id);

      if (idx > -1) {
        return true;
      }
    } else if (item.type == 25) {
      const idx = this.yolobookS8itemsToDownload.findIndex(x => x.id == item.id);

      if (idx > -1) {
        return true;
      }
    }  else if (item.type == 40 || item.type == 47) {
      const idx = this.yoloStories.findIndex(x => x.id == item.id);

      if (idx > -1) {
        return true;
      }
    }
    else if (item.type === 22 || item.type === 36) {
      const idx = this.yoloHardMini.findIndex(x => x.id == item.id);

      if (idx > -1) {
        return true;
      }
    } else  if (item.type === 27 || item.type === 39) {
      const idx = this.yoloHardMaxiS.findIndex(x => x.id == item.id);

      if (idx > -1) {
        return true;
      }
    } else if (item.type === 26 || item.type === 38) {
      const idx = this.yoloHardMiniS.findIndex(x => x.id == item.id);

      if (idx > -1) {
        return true;
      }
    } else if (item.type === 23 || item.type === 37 || item.type === 49) {
      const idx = this.yoloHardMaxi.findIndex(x => x.id == item.id);

      if (idx > -1) {
        return true;
      }
    }
    return false;
  }

  check8() {
    if (this.yolobook8itemsToDownload.length < 4)
      return;

    let diff = this.yolobook8itemsToDownload.length % 8;

    if (diff > 4)
      diff = 8 - diff;

    this.ybDiff = diff;

    if (this.yolobook8itemsToDownload.length == 0)
      this.ybDiff = -1;
    //num = (a / b) >> 0;
  }

  check8Xl() {
    if (this.yolobook8XlitemsToDownload.length < 3)
      return;

    let diff = this.yolobook8XlitemsToDownload.length % 6;

    if (diff > 3)
      diff = 6 - diff;

    this.ybDiffXl = diff;

    if (this.yolobook8XlitemsToDownload.length == 0)
      this.ybDiffXl = -1;
    //num = (a / b) >> 0;
  }

  checkS8() {
    if (this.yolobookS8itemsToDownload.length < 4)
      return;

    let diff = this.yolobookS8itemsToDownload.length % 8;

    if (diff > 4)
      diff = 8 - diff;

    this.ybDiffS = diff;

    if (this.yolobookS8itemsToDownload.length == 0)
      this.ybDiffS = -1;
    //num = (a / b) >> 0;
  }

  checkS8Xl() {
    if (this.yolobookS8XlitemsToDownload.length < 3)
      return;

    let diff = this.yolobookS8XlitemsToDownload.length % 6;

    if (diff > 3)
      diff = 6 - diff;

    this.ybDiffXlS = diff;

    if (this.yolobookS8XlitemsToDownload.length == 0)
      this.ybDiffXlS = -1;
    //num = (a / b) >> 0;
  }

  checkCal2() {
    if (this.yoloCalendarItemsToDownload.length < 2)
      return;

    let diff = this.yoloCalendarItemsToDownload.length % 2;

    if (diff > 2)
      diff = 2 - diff;

    this.ybCalDiff = diff;

    if (this.yoloCalendarItemsToDownload.length == 0)
      this.ybCalDiff = -1;
    //num = (a / b) >> 0;
  }

  checkCal2Kids() {
    if (this.yoloCalendarKidsItemsToDownload.length < 2)
      return;

    let diff = this.yoloCalendarKidsItemsToDownload.length % 2;

    if (diff > 2)
      diff = 2 - diff;

    this.ybCalKidsDiff = diff;

    if (this.yoloCalendarKidsItemsToDownload.length == 0)
      this.ybCalKidsDiff = -1;
    //num = (a / b) >> 0;
  }

  checkCal2Basic() {
    if (this.yoloCalendarBasicItemsToDownload.length < 2)
      return;

    let diff = this.yoloCalendarBasicItemsToDownload.length % 2;

    if (diff > 2)
      diff = 2 - diff;

    this.ybCalBasicDiff = diff;

    if (this.yoloCalendarBasicItemsToDownload.length === 0)
      this.ybCalBasicDiff = -1;
    //num = (a / b) >> 0;
  }

  checkHardMini() {
    if (this.yoloHardMini.length < 2) {
      return;
    }

    let diff = this.yoloHardMini.length % 2;

    if (diff > 2) {
      diff = 2 - diff;
    }

    this.yhmini = diff

    if (this.yoloHardMini.length === 0) {
      this.yhmini = -1;
    }
  }

  checkHardMaxi() {
    if (this.yoloHardMaxi.length < 2) {
      return;
    }

    let diff = this.yoloHardMaxi.length % 2;


    if (diff > 2) {
      diff = 2 - diff;
    }

    this.yhmaxi = diff

    if (this.yoloHardMaxi.length === 0) {
      this.yhmaxi = -1;
    }

  }

  checkHardMiniS() {
    if (this.yoloHardMiniS.length < 2) {
      return;
    }

    let diff = this.yoloHardMiniS.length % 2;

    if (diff > 2) {
      diff = 2 - diff;
    }

    this.yhminiS = diff

    if (this.yoloHardMiniS.length === 0) {
      this.yhminiS = -1;
    }
  }

  checkYoloStories() {
    if (this.yoloStories.length < 2) {
      return;
    }

    let diff = this.yoloStories.length % 2;


    if (diff > 2) {
      diff = 2 - diff;
    }

    this.yStories = diff

    if (this.yoloStories.length === 0) {
      this.yStories = -1;
    }

  }

  checkHardMaxiS() {
    if (this.yoloHardMaxiS.length < 2) {
      return;
    }

    let diff = this.yoloHardMaxiS.length % 2;

    if (diff > 2) {
      diff = 2 - diff;
    }

    this.yhmaxiS = diff

    if (this.yoloHardMaxiS.length === 0) {
      this.yhmaxiS = -1;
    }
  }

  displayCheck(row) {
    return (row.status == 3 || row.status == 4 || row.status == 5);
  }

  getRowId(row) {
    return row.id;
  }

  getRowDetailHeight(row
                       :
                       OrderList
  ) {
    if (row) {
      return row.items.length * 70;
    }
  }

  formatDate(value) {
    return new Date(value).toISOString;
  }

  toTop(id) {
    document.getElementById(id).scrollIntoView({behavior: "smooth"});
  }

  scroll = (): void => {
    if (window.pageYOffset > 120) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  }

  openStatus() {
    this.hideCancelButton = false;
  }

  findType(type) {
    if (type == 1) {
      return "YoloBook";
    } else if (type == 2) {
      return "YoloBox";
    } else if (type == 3) {
      return "YoloFrame";
    } else if (type === 4) {
      return "YoloBook XL";
    } else if (type == 6) {
      return 'YoloVintage';
    } else if (type == 7) {
      return 'YoloSquare Mini';
    } else if (type == 8) {
      return 'YoloClassic';
    } else if (type == 9) {
      return 'YoloOriginal';
    } else if (type == 10) {
      return 'YoloSquare Maxi';
    } else if (type == 11) {
      return 'YoloRegular';
    } else if (type == 12) {
      return 'YoloSquare';
    } else if (type == 20) {
      return 'YoloCalendar Basic';
    } else if (type == 21) {
      return 'YoloCalendar Kids';
    } else if (type == 22 || type == 36) {
      return 'HardCover Square';
    } else if (type == 23 || type == 37) {
      return 'HardCover Landscape';
    } else if (type == 24) {
      return 'YoloBook Maxi S';
    } else if (type == 25) {
      return 'YoloBook Mini S';
    } else if (type == 26 || type == 38) {
      return 'HardCover Square S';
    } else if (type == 27 || type == 39) {
      return 'HardCover Landscape S';
    } else if (type == 30) {
      return 'YoloTiles Square';
    } else if (type == 31) {
      return 'YoloTiles Hexa';
    } else if (type == 33) {
      return 'YoloPlanner';
    } else if (type == 34) {
      return 'YoloNotes';
    } else if (type == 35 || type == 46)  {
      return 'YoloPremium';
    } else if (type == 40) {
      return 'YoloStories';
    } else if (type == 41) {
      return 'YoloCanvas';
    } else if (type == 42) {
      return 'Yolo Canvas 30x30 + Text';
    } else if (type == 43) {
      return 'Yolo Canvas 30x30';
    } else if (type == 44) {
      return 'Canvas 20x30';
    } else if (type == 45) {
      return 'Canvas 30x40';
    } else if(type == 47) {
      return 'KK Partizan Stories';
    }else if(type == 48) {
      return 'HCL KK Partizan (Aba, Euroleague)';
    }else if(type == 49) {
      return 'HardCover Landscape Partizan';
    }

  }

  resetTypeFilter()
    :
    void {
    this.typeFilterForm.patchValue({
      type: [''],
    })
    this.filter.typeArray = null;
  }

  resetPayingMethodFilter()
    :
    void {
    this.typeFilterForm.patchValue({
      payingMethod: [''],
    })
  }

  resetStatusFilter()
    :
    void {
    this.filteredStatus = [];
    this.statusFilterForm.patchValue({
      status: [''],
    });
    this.filter.statusArray = [];
    this.filteredStatus.forEach((s: any) => {
      this.filter.statusArray.push(s.value);
    });

    if (this.filter.statusArray.length === 0
    ) {
      this.filter.statusArray = null;
    }
  }

  resetPayingStatusFilter()
    :
    void {
    console.log('Usao', this.filter.payingStatus);
    console.log('Usao1', this.statusFilterForm.controls.payingStatus.value);
    this.filter.payingStatus = null
    this.statusFilterForm.patchValue({
      payingStatus: [''],
    })
  }

  resetFromDateFilter()
    :
    void {
    this.filter.fromDate = '';
    this.dateFilterForm.patchValue({
      fromDate: '',
    });
  }

  resetToDateFilter()
    :
    void {
    this.filter.toDate = '';
    this.dateFilterForm.patchValue({
      toDate: '',
    });
  }

  resetSelectFormFilter()
    :
    void {
    this.filter.device = null
    this.selectFilterForm.patchValue({
      device: '',
    });
  }

  getTranslation(value) {
    return this.translate.instant(value);
  }

  onPrintOrdersClick() {
    const ordersToPrint: OrderList[] = [];

    this.ordersData.forEach((order: OrderList) => {
      if (order.status == 4 || order.status == 5)
        ordersToPrint.push(order);
    });

    localStorage.setItem('printOrders', JSON.stringify(ordersToPrint));
    this.router.navigate(['print-orders']);
  }

  getCombinations() {
    this.dataLoading = true;
    const ids = [];

    this.ordersData.forEach((order: OrderList) => {
      ids.push(order.id);
    });

    this.apiService.getCombinations(this.filter).subscribe(
      data => {
        const date = new Date().toISOString();
        this.dataLoading = false;
        saveAs(data, "combinations_" + date + ".pdf");
      }
    )


  }

  onPrintOrdersPDFClick() {
    this.apiService.printOrdersPdf(this.filter).subscribe(
      data => {
        saveAs(data, "orders.pdf");
      }
    )
  }

  updateProductPrice(event, product, orderId) {
    const data = {id: product.id, price: event.target.value, orderId};
    this.apiService.updateOrderProductPrice(data).subscribe(
      (order: OrderList) => {
        const index = this.orders.findIndex((x) => x.id == order.id);
        this.orders[index] = order;
        this.orders = [...this.orders];
        setTimeout(() => {
          this.expand();
        }, 100);
        this.snackBar.open(this.translate.instant('saved') + " ID: " + orderId, "Ok", {duration: 2000});
      },
      (err) => {
        this.snackBar.open(this.translate.instant('thereWasAnError') + " ID: " + orderId, "Ok", {duration: 2000});
      });
  }

  updateAlbumCover(event, albumId)
  {
    const data = {newCoverId: event.target.value, albumId};
    this.apiService.updateAlbumCover(data).subscribe(
      (order: OrderList) => {
          const index = this.orders.findIndex((x) => x.id == order.id);
          this.orders[index] = order;
          this.orders = [...this.orders];
          setTimeout(() => {
            this.expand();
          }, 100);
        this.snackBar.open(this.translate.instant('saved') + " New Cover ID: " + event.target.value, "Ok", {duration: 2000});
      },
      (err) => {
        this.snackBar.open(this.translate.instant('thereWasAnError') + " Album ID: " + albumId, "Ok", {duration: 2000});
      });
  }

  expand() {
    this.table.rowDetail.collapseAllRows();
    for (let i = 0; i < this.orders.length; i++) {
      if (this.expandedRows.indexOf(this.orders[i].id) > -1) {
        this.table.rowDetail.toggleExpandRow(this.orders[i])
      }
    }
  }

  updateDeliveryPrice(event, orderId) {
    const data = {id: orderId, delivery: event.target.value};
    this.apiService.updateOrderDeliveryPrice(data).subscribe(
      (order: OrderList) => {
        const index = this.orders.findIndex((x) => x.id == order.id);
        this.orders[index] = order;
        this.orders = [...this.orders];
        this.snackBar.open(this.translate.instant('saved') + " ID: " + orderId, "Ok", {duration: 2000});
      },
      (err) => {
        this.snackBar.open(this.translate.instant('thereWasAnError') + " ID: " + orderId, "Ok", {duration: 2000});
      });
  }

  facture() {
    const dialogRef = this.dialog.open(FactureComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filter.responsible_person = result.responsible_person;
        this.filter.sack = result.sack;
        this.filter.account_number = result.account_number;
        this.apiService.getFacture(this.filter).subscribe(
          (response) => {
            saveAs(response, "facture.docx");
            this.snackBar.open(this.translate.instant('saved'), 'OK', {duration: 3000});
          }
        )

        if (this.country == "ro") {
          this.apiService.getFactureWithCountry(this.filter, "enro").subscribe(
            (response) => {
              console.log(this.filter);
              saveAs(response, "facture.docx");
              this.snackBar.open(this.translate.instant('saved'), 'OK', {duration: 3000});
            }
          )
        }

        this.apiService.exportOrdersWithProducts(this.filter, this.country).subscribe(
          (response) => {
            saveAs(response, "export.xlsx");
          }
        )


      }


    });
  }

  checkPermission(page) {
    console.log("permissions" + JSON.stringify(this.user.permissions));
    const permissions = this.user.permissions;
    const rightPermission = permissions.find((x) => x.page === page);
    if (rightPermission) {
      if (rightPermission.edit === 1) {
        this.allowedEdit = true;
      } else {
        this.allowedEdit = false;
      }
      if (rightPermission.view === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  loadNewOrders(addNew = 1) {
    if (addNew == 1) {
      this.page = this.page + 1;
    }
    this.isLoading = true;
    this.dataLoading = true;
    this.apiService.getOrdersListWithPaging(this.filter, this.page).subscribe((data: any) => {
      this.isLoading = false;
      this.dataLoading = false;
      if (data.length < 10) {
        this.noMoreData = true;
      }
      if (addNew == 1) {
        if (data.length > 0) {
          this.orders = [...this.orders, ...data];
        } else {
          this.noMoreData = true;
        }
      } else {
        for (let i = 0; i <= this.page; i++) {
          this.apiService.getOrdersListWithPaging(this.filter, i).subscribe((data: any) => {
            this.isLoading = false;
            this.dataLoading = false;
            if (data.length < 10) {
              this.noMoreData = true;
            }
            if (data.length > 0) {
              if (i == 0) {
                this.orders = data;
              } else {
                this.orders = [...this.orders, ...data];
              }

            }
          });
        }
      }

    }, (err) => {
      console.log(err);
      this.isLoading = false;
      this.dataLoading = false;
      this.snackBar.open("Greška!", 'OK', {duration: 3000});
    });
  }

  refund(order) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {order};
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.dialog.open(RefundDialogComponent, dialogConfig);
  }

  payAgainOrder(order) {
    this.apiService.payAgainOrder(order.id).subscribe(
      (data: { payingStatus }) => {
        order.payingStatus = data.payingStatus;
        this.snackBar.open(this.translate.instant('orderPaidAgain'), 'OK', {duration: 3000});
      }, (err) => {
        console.log(err);
        this.snackBar.open(err.error.message, 'OK', {duration: 3000});
      }
    )

  }

  hideButtonPayAgain(row
                       :
                       any
  ):
    boolean {
    return row.payingMethod == 2 && (row.payingStatus == 1 || row.payingStatus == 3 ||
      row.payingStatus == 4 || row.payingStatus == 5 || row.payingStatus == 7);
  }

  snackBarMessage(status
                    :
                    number
  ):
    void {
    if (status === 5
    ) {
      this.snackBar.open(this.translate.instant('CancelSelect'), 'OK');
    }
  }

  openUser(id) {
    this.router.navigate([`main`, 'users', id, 'details']);
  }

  ngOnDestroy() {
    if (this.obsevarble) {
      this.obsevarble.unsubscribe();
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.resetCounter.unsubscribe();

    localStorage.setItem('collums2', JSON.stringify(this.collums));
    localStorage.setItem('limit', this.limit.toString());
  }
}
