import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';
import {
  Cost,
  DynamicLink,
  DynamicLinks,
  Filter,
  InvoiceFilter,
  OrderItem,
  OrderList,
  OrderNotification,
  Promo,
  Survey,
  UpsellingAction
} from "./models";
import {environment} from "../environments/environment";
import {SortData, FilterData} from './utils';
import {BehaviorSubject} from "rxjs/BehaviorSubject";

@Injectable()

export class ApiService {
  BASE_API_URL = environment.url;
  BASE_API_URL_V2 = environment.urlV2; //'https://stage.yolobook.com'; //

  headers = new HttpHeaders({'Content-Type': 'application/json'});
  options = {headers: this.headers};

  private envSource = new BehaviorSubject<string>(null);
  env = this.envSource.asObservable();

  changeEnv(env: string) {
    if (env != null) {
      this.BASE_API_URL = env;
    } else {
      this.BASE_API_URL = environment.url;
    }
  }

  changeEnvApi2(env: string) {
    if (env != null) {
      this.BASE_API_URL_V2 = env;
    } else {
      this.BASE_API_URL_V2 = environment.urlV2;
    }
  }

  constructor(private http: HttpClient) {
  }

  /**
   * Admin
  */
  login(username: string, password: string) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/auth/admin-login', JSON.stringify({
    //return this.http.post(this.BASE_API_URL + '/panel/admin/login', JSON.stringify({
        username: username,
        password: password
    }), this.options);
  }

  logout() {
    return this.http.delete(this.BASE_API_URL_V2 + '/api/admin/logout', this.authToken());
  }

  checkAdmin() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/check/api/key', this.authToken());
  }

  getDayOffs() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/day/off', this.authToken());
  }
  public uploadImage(file) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/upload/image', file,
      this.authFileToken());
  }

  addAdmin(admin) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/add/new/admin', JSON.stringify(admin), this.authToken());
  }

  deleteAdmin(id) {
    return this.http.delete(this.BASE_API_URL_V2 + '/api/admin/delete/admin/' + id, this.authToken());
  }

  getAdminPositionHistory(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/position/history/' + id , this.authToken());
  }

  getPositions() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/positions/get', this.authToken());
  }

  getSocials(country) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admins/social/' + country, this.authToken());
  }

  updateAdmin(admin) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/update', JSON.stringify(admin), this.authToken());
  }

  sectorsList() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/sectors/all', this.authToken());
  }

  sectorEmployees(name, phone, email, position) {
    const data = {filter: {name, phone, email, position}};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/sectors/employees', JSON.stringify(data), this.authToken());
  }

  /**
   * Notifications
   */

  getUnreadNotifications() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/new/notifications', this.authToken());
  }

  readNotifications() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/read/notifications', this.authToken());
  }

  allNotifications(page) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/notifications/' + page, this.authToken());
  }

  getProducts(data) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/products', data, this.authToken());
  }

  /**
   * Temporal price
   */

  getTemporalPriceProducts() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/temporal/price/products', this.authToken());
  }

  getTemporalPrices(page) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/temporal/prices', this.authToken());
  }

  createTemporalPrice(temporalPrice) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/temporal/price/create', JSON.stringify(temporalPrice), this.authToken());
  }

  updateTemporalPrice(temporalPrice) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/temporal/price/update', JSON.stringify(temporalPrice), this.authToken());
  }

  /**
   * Download
   */
  public downloadYolobook(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/yolobook/' + id;
    return this.http.get(url, this.authToken());
  }

  public downloadYolobookS(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/yolobooks/' + id;
    return this.http.get(url, this.authToken());
  }

  public downloadSinglePhoto(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/singlephoto/' + id;
    return this.http.get(url, this.authToken());
  }

  public downloadYoloHardMiniGroup(order): any {
    const endPoint = this.BASE_API_URL_V2 + '/api/admin/download/yolohardmini2';
    return this.http.post(endPoint, order, this.authToken());
  }

  public downloadYoloHardMaxiGroup(order): any {
    const endPoint = this.BASE_API_URL_V2 + '/api/admin/download/yolohardmaxi2';
    return this.http.post(endPoint, order, this.authToken());
  }

  public downloadYoloHardMiniSGroup(order): any {
    const endPoint = this.BASE_API_URL_V2 + '/api/admin/download/yolohardminis2';
    return this.http.post(endPoint, order, this.authToken());
  }

  public downloadYoloStoriesGroup(order): any {
    const endPoint = this.BASE_API_URL_V2 + '/api/admin/download/yolostories2';
    return this.http.post(endPoint, order, this.authToken());
  }

  public downloadYoloHardMaxiSGroup(order): any {
    const endPoint = this.BASE_API_URL_V2 + '/api/admin/download/yolohardmaxis2';
    return this.http.post(endPoint, order, this.authToken());
  }

  public downloadYoloHardMiniSingle(id): any {
    const endPoint = this.BASE_API_URL_V2 + `/api/admin/download/yolohardmini/${id}`;
    return this.http.get(endPoint, this.authToken());
  }

  public downloadYoloHardMaxiSingle(id): any {
    const endPoint = this.BASE_API_URL_V2 + `/api/admin/download/yolohardmaxi/${id}`;
    return this.http.get(endPoint, this.authToken());
  }

  public downloadYoloHardMiniSingleS(id): any {
    const endPoint = this.BASE_API_URL_V2 + `/api/admin/download/yolohardminis/${id}`;
    return this.http.get(endPoint, this.authToken());
  }

  public downloadYoloHardMaxiSingleS(id): any {
    const endPoint = this.BASE_API_URL_V2 + `/api/admin/download/yolohardmaxis/${id}`;
    return this.http.get(endPoint, this.authToken());
  }

  public downloadYoloPremium(id): any {
    const endPoint = this.BASE_API_URL_V2 + `/api/admin/download/yolopremium/${id}`;
    return this.http.get(endPoint, this.authToken());
  }

  public downloadYoloStories(id): any {
    const endPoint = this.BASE_API_URL_V2 + `/api/admin/download/yolostories/${id}`;
    return this.http.get(endPoint, this.authToken());
  }

  public downloadYoloCanvas(id): any {
    const endPoint = this.BASE_API_URL_V2 + `/api/admin/download/canvas/${id}`;
    return this.http.get(endPoint, this.authToken());
  }

  public downloadCalendar(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/calendar/' + id;
    return this.http.get(url, this.authToken());
  }

  public downloadTiles(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/yolotiles/' + id;
    return this.http.get(url, this.authToken());
  }

  public downloadYolobookXl(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/yolobook/xl/' + id;

    return this.http.get(url, this.authToken());
  }

  public downloadYolobookXlS(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/yolobook/xls/' + id;
    return this.http.get(url, this.authToken());
  }

  public downloadFrameXL1H(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/framexl1h/' + id;
    return this.http.get(url, this.authToken());
  }

  public downloadFrameXL15(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/framexl15/' + id;
    return this.http.get(url, this.authToken());
  }

  public downloadYoloBook8(items: OrderItem[]): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/yolobook8';
    const body = [];

    items.forEach((i) => {
      body.push(i.id);
    });
    return this.http.post(url, JSON.stringify(body), this.authToken());
  }

  public downloadYolobook8XL(items: OrderItem[]): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/xl/yolobook6';
    const body = [];

    items.forEach((i) => {
      body.push(i.id);
    });

    return this.http.post(url, JSON.stringify(body), this.authToken());
  }

  public downloadYoloBookS8(items: OrderItem[]): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/yolobooks8';
    const body = [];

    items.forEach((i) => {
      body.push(i.id);
    });

    return this.http.post(url, JSON.stringify(body), this.authToken());
  }

  public downloadYolobook8XLS(items: OrderItem[]): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/xls/yolobook6';
    const body = [];

    items.forEach((i) => {
      body.push(i.id);
    });

    return this.http.post(url, JSON.stringify(body), this.authToken());
  }

  public downloadCalendar2(items: OrderItem[]): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/calendar2';
    const body = [];

    items.forEach((i) => {
      body.push(i.id);
    });

    return this.http.post(url, JSON.stringify(body), this.authToken());
  }

  public downloadYolobox(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/yolobox/' + id;
    return this.http.get(url, this.authToken());
  }

  public downloadFrame1(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/frame1/' + id;
    return this.http.get(url, this.authToken());
  }

  public downloadFrame4(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/frame4/' + id;
    return this.http.get(url, this.authToken());
  }

  public downloadFrameXL1(id): any {
    const url = this.BASE_API_URL_V2 + '/api/admin/download/framexl1/' + id;

    return this.http.get(url, this.authToken());
  }

  downloadInvoiceBook(url) {
    const data = {'url': url};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/invoice/outgoing/download',
      JSON.stringify(data), this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: 'application/pdf'});
    });
  }

  downloadSpendingsXls(url) {
    const data = {'url': url};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/invoice/outgoing/download',
      JSON.stringify(data), this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: 'application/xls'});
    });
  }

  downloadExportUsersXls(url) {
    const data = {'url': url};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/download/file',
      JSON.stringify(data), this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: 'application/xls'});
    });
  }

  downloadSpendingsPdf(url) {
    const data = {'url': url};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/invoice/outgoing/download',
      JSON.stringify(data), this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: 'application/pdf'});
    });
  }

  downloadXls(ids, date = '') {
    const url = environment.url + '/panel/excell/new/print';
    return this.http.post(url, ids, this.authDateToken(date)).map(
      (res) => {
        return res;
      });
  }

  downloadPdfBa(ids){
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/bs-post-office/send', JSON.stringify(ids), this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: ' application/zip'})
    });
  }

  downloadPdfRo(ids){
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/ro-post-office/send', JSON.stringify(ids), this.authTokenBlob()).map((res) => {
      return res;
    });
  }

  downloadRoPostOffice(url) {
    const data = {'url': url};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/download/file', JSON.stringify(data), this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: 'application/pdf'});
    });
  }

  downloadInvoices(data) {
    const url = this.BASE_API_URL_V2 + '/api/admin/invoices/download';
    return this.http.post(url, data, this.authTokenBlob()).map(
      (res) => {
        return new Blob([res], {type: ' application/zip'})
      });
  }

  autoDownloadAlbum8(url) {
    const data = {'url': url};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/download/file', JSON.stringify(data), this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: 'application/pdf'});
    });
  }

  autoDownloadYoloBook(url) {
    const data = {'url': url};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/download/file', JSON.stringify(data), this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: 'application/pdf'});
    });
  }

  autoDownloadYoloBox(url) {
    const data = {'url': url};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/download/file', JSON.stringify(data), this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: 'application/zip'});
    });
  }

  autoDownloadYoloFrame(url) {
    const data = {'url': url};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/download/file', JSON.stringify(data), this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: 'application/jpg'});
    });
  }

  /**
  * Invoices
  * */


  public createBill(orderId) {
    return this.http.get<Survey>(this.BASE_API_URL_V2 + `/api/admin/invoice/create/${orderId}`, this.authToken());
  }

  public cancelBill(orderId) {
    return this.http.get<Survey>(this.BASE_API_URL_V2 + `/api/admin/invoice/delete/${orderId}`, this.authToken());
  }

  getInvoicesNew(filter: InvoiceFilter, page = 0) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/invoice/filter/' + page, filter, this.authToken());
  }

  printInvoicesBook(filter: InvoiceFilter) {
    filter.isDeleted = 3;
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/invoice/outgoing', filter, this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: 'application/pdf'});
    });
  }
  sendInvoices(ids) {
    const url = this.BASE_API_URL_V2 + '/api/admin/orders/invoice/send';
    return this.http.post(url, ids, this.authToken()).map(
      (res) => {
        return res;
      });
  }

  public uploadReport(f) {
    const url = this.BASE_API_URL_V2 + '/api/admin/invoice/report/import';

    return this.http.post(url, f, this.authFileToken());
  }

  /**
   * Order
   */
  deleteOrder(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/orders/delete/' + id, this.authToken());
  }

  setOrderStatus(id, status) {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/orders/${id}/status/${status}`, this.authToken());
  }

  mergeOrdersNew(ids, promo, price) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/order/merge/' + promo + '/' + price, JSON.stringify(ids), this.authToken());
  }

  getOrdersList(filter: Filter) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/orders/get/all', filter, this.authToken());
  }

  getOrdersListWithPaging(filter: Filter, page) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/orders/get/paging/' + page, filter, this.authToken());
  }

  getOrdersId(filter: Filter) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/orders/filter/ids', filter, this.authToken());
  }

  getOrdersInfo(filter: Filter) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/orders/info', filter, this.authToken());
  }

  changePaymentType(id, type) {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/orders/${id}/payment/${type}`, this.authToken());
  }

  toggleOrderFree(orderId, free) {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/orders/toggle/${orderId}/free/${free}`, this.authToken());
  }

  updateOrderDeliveryPrice(data) {
    return this.http.put(this.BASE_API_URL_V2 + '/api/admin/orders/delivery/update' , data, this.authToken());
  }

  updateOrder(order: any) {
    return this.http.put(this.BASE_API_URL_V2 + '/api/admin/orders/address/update', order, this.authToken());
  }

  updateOrderPromo(order: any) {
    return this.http.put(this.BASE_API_URL_V2 + '/api/admin/orders/promo/update', order, this.authToken());
  }
  exportOrders(data, country) {
    const url = this.BASE_API_URL_V2 + '/api/admin/orders/export';
    return this.http.post(url, data, this.authTokenBlobWithCountry(country)).map(
      (res) => {
        return new Blob([res], {type: 'application/xlsx'});
      });
  }
  printOrdersPdf(filter: Filter) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/orders/pdf', filter, this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: 'application/pdf'});
    });
  }

  public listingNotification() {
    const endPoint = this.BASE_API_URL_V2 + `/api/admin/order/notifications/get`;
    return this.http.get<OrderNotification[]>(endPoint, this.authToken());
  }

  getOrderHistory(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/order/history/' + id, this.authToken());
  }

  updateOrderProductPrice(data) {
    return this.http.put(this.BASE_API_URL_V2 + '/api/admin/order/change/product/price' , data, this.authToken());
  }

  setSendingsExtra(orderId) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/order/sending/extra/' + orderId, this.authToken());
  }

  public copyOrder(orderId: Number) {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/order/copy/${orderId}`, this.authToken());
  }

  public checkIfOrderHasFiles(orderId: Number) {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/download/order/files/check/${orderId}`, this.authToken());
  }

  refundOrder(data) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/order/refund', data, this.authToken());
  }

  exportOrdersWithProducts(data, country) {
    let ignoreCountry = 0;
    if (country == 'ro') {
      ignoreCountry = 1;
    }
    const url = this.BASE_API_URL_V2 + '/api/admin/orders_products/export';
    return this.http.post(url, data, this.authTokenBlobWithCountry(country, ignoreCountry)).map(
      (res) => {
        return new Blob([res], {type: 'application/xlsx'});
      });
  }

  updateOrderPrice(order: OrderList) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/order/price/change', order, this.authToken());
  }

  getOrderById(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/order/' + id, this.authToken());
  }

  /*
  * Albums
  * */

  copyAlbum(id) {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/orders/album/copy/${id}`, this.authToken());
  }

  deleteAlbum(id) {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/orders/album/delete/${id}`, this.authToken());
  }

  getAlbumsByIds(idsText: string) {
    const ids = idsText.split(",");
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/orders/items/get', JSON.stringify(ids), this.authToken());
  }

  public changeAlbumPrices(data) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/orders/item/price', JSON.stringify(data), this.authToken());
  }

  public getProductAlbums() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/product/albums', this.authToken());
  }

  updateAlbumCover(data){
    return this.http.put(this.BASE_API_URL_V2 + '/api/admin/order/change/album/cover' , data, this.authToken());
  }

  /**
   * Costs
   */
  getAllCosts() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/costs/all', this.authToken());
  }

  addCost(cost: Cost) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/cost', JSON.stringify(cost), this.authToken());
  }

  deleteCost(id: number) {
    return this.http.delete(this.BASE_API_URL_V2 + '/api/admin/costs/' + id, this.authToken());
  }

  /**
   * Covers
   */
  createCover(cover) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/cover', JSON.stringify(cover), this.authToken());
  }

  public uploadImageCoverProfile(file, id) {
    return this.http.put(this.BASE_API_URL_V2 + '/api/admin/cover/front/' + id, file,
      this.authFileToken());
  }

  public uploadImageCoverBack(file, id) {
    return this.http.put(this.BASE_API_URL_V2 + '/api/admin/cover/back/' + id, file,
      this.authFileToken());
  }

  getCoverByCategory(id, start, end) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/covers/' + id + "?start=" + start + "&end=" + end, this.authToken());
  }

  toggleCover(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/cover/toggle/' + id, this.authToken());
  }

  toggleCoverCategory(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/cover-category/toggle/' + id, this.authToken());
  }

  getCoverHistory(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/cover/history/get/' + id, this.authToken());
  }

  addCoverHistory(id, change) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/cover/history/add/' + id + '/' + change, this.authToken());
  }

  changeCoversCount(id, change) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/cover/change/' + id + '/' + change, this.authToken());
  }

  getCoverTypes() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/cover/types', this.authToken());
  }

  getCoverCategories(type, start, end) {
    return this.http.get(
      this.BASE_API_URL_V2 + '/api/admin/cover-categories/' + type + "?start=" + start + "&end=" + end, this.authToken()
    );
  }

  public addNewCover(data: any) {
    return this.http.post<DynamicLink>(this.BASE_API_URL_V2 + `/api/admin/cover`, data, this.authToken());
  }

  public updateCover(data: any) {
    return this.http.put<DynamicLink>(this.BASE_API_URL_V2 + `/api/admin/cover`, data, this.authToken());
  }

  public addNewCoverCategory(data: any) {
    return this.http.post<DynamicLink>(this.BASE_API_URL_V2 + `/api/admin/cover-category`, data, this.authToken());
  }

 /*
 * DYNAMIC LINKS
 */
  public getAllDynamicLinks() {
    return this.http.get<DynamicLinks>(this.BASE_API_URL_V2 + '/api/admin/dynamic/links', this.authToken());
  }

  public createDynamicLink(data: any) {
    return this.http.post<DynamicLink>(this.BASE_API_URL_V2 + `/api/admin/dynamic/link`, data, this.authToken());
  }

  public updateDynamicLink(data: any) {
    return this.http.patch<DynamicLink>(this.BASE_API_URL_V2 + `/api/admin/dynamic/link/${data.id}`, data, this.authToken());
  }

  public deleteDynamicLink(id: number) {
    return this.http.delete<DynamicLink>(this.BASE_API_URL_V2 + `/api/admin/dynamic/link/${id}`, this.authToken());
  }

  /*
  * Banners
  */

  public getBanners(country) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/banner/get/' + country, this.authToken());
  }
  public createBanner(banner) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/banner/create', JSON.stringify(banner), this.authToken())
  }

  public changeActivityTrue(banner) {
    const data = {'state': true};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/banner/change/activity/' + banner,
      JSON.stringify(data), this.authToken());
  }

  public changeActivityFalse(banner) {
    const data = {'state': false};

    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/banner/change/activity/' + banner,
      JSON.stringify(data), this.authToken());
  }

  public uploadBanner(file) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/banner/upload', file,
      this.authFileToken());
  }

  public changePriority(banner) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/banner/change/priority/' + banner.id,
      JSON.stringify(banner), this.authToken());
  }

  /*
  * Permissions
  */

  getPermissions(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/permissions/get/' + id, this.authToken());
  }

  getPermissionsForUser(user) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/permissions/employee/get/' + user.id, this.authToken());
  }

  updatePermissions(id, permission) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/permissions/sector/toggle/' + id,
      JSON.stringify(permission),
      this.authToken()
    );
  }

  public getUserPermissionsForLocaleStorage(id: number) {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/${id}`, this.authToken());
  }

  updateOrCreatePermissions(id, permissions) {
    const data = {'permissions': permissions};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/permissions/employee/toggle/' + id, JSON.stringify(data), this.authToken());
  }

  addPermissions(id, permissions) {
    const data = {'permissions': permissions};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/permissions/employee/toggle/' + id , JSON.stringify(data), this.authToken());
  }

  /*
  * Tracking
  */

  uploadTracking(formData, country) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/import/tracking/' + country, formData, this.authFileToken())
  }

  /*
  * Promo Code
  */
  getAllPromos(page) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/promo/getAll/' + page, this.authToken());
  }

  updatePromo(promo: Promo) {
    return this.http.patch(this.BASE_API_URL_V2 + '/api/admin/promo/update/' + promo.id, JSON.stringify(promo), this.authToken());
  }

  createPromo(promo: Promo) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/promo/create', JSON.stringify(promo), this.authToken());
  }

  deletePromo(promo: Promo) {
    return this.http.delete(this.BASE_API_URL_V2 + '/api/admin/promo/delete/' + promo.id, this.authToken());
  }

  /*
  * Machines
  * */
  addMachine(machine) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/machine', JSON.stringify(machine), this.authToken());
  }

  editMachine(machine) {
    return this.http.patch(this.BASE_API_URL_V2 + '/api/admin/update/'+machine.id, JSON.stringify(machine), this.authToken());
  }

  deleteMachine(id) {
    return this.http.delete(this.BASE_API_URL_V2 + '/api/admin/machine/delete/' + id, this.authToken());
  }

  getMachines() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/machines/get/all', this.authToken());
  }
  getMachineDetails(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/machine/' + id, this.authToken());
  }

  getMachineRepair(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/repair/history/' + id, this.authToken());
  }

  /*
  * Repair
  * */

  addRepair(repair) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/repair/history/add', JSON.stringify(repair), this.authToken());
  }

  deleteRepair(id) {
    return this.http.delete(this.BASE_API_URL_V2 + '/api/admin/repair/history/' + id, this.authToken());
  }

  /*
  * Supplies
  * */

  getSupplies() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/supplies/get/all', this.authToken());
  }

  getSupplyHistories(supplyId) {
    const data = {'supplyId':supplyId.id};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/supplies/history', JSON.stringify(data), this.authToken());
  }

  public updateLagerValue(data) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/supplies/lager/' + data.id, JSON.stringify(data), this.authToken());
  }

  addSupplyHistory(supplyId, change) {
    const data = {supplyId, change};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/supplies/history/add', JSON.stringify(data), this.authToken());
  }

  /*
  * Product Images
  * */

  getProductImages() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/products/images', this.authToken());
  }

  public uploadProductImage(file: any) {
    const endpoint = this.BASE_API_URL_V2 + `/api/admin/product/image/upload`;
    return this.http.post<DynamicLink>(endpoint, file, this.authFileToken());
  }

  public updateProductImage(product) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/product/image/update', JSON.stringify(product), this.authToken());
  }

  public updateProductDetailImages(product) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/product/details/image/update', JSON.stringify(product), this.authToken());
  }

  /*
  * Stat
  * */

  getStatisticsUserPerStatus(start = null, end = null)  {
    if (start && end) {
      return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/user-status/${start}/${end}` , this.authToken());
    } else {
      return this.http.get(this.BASE_API_URL_V2 + '/api/admin/statistics/user-status' , this.authToken());

    }
  }

  getStatisticsCustomerSatisfaction(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/user-satisfaction/${start}/${end}` , this.authToken());
  }

  getUserOrdersStatistics(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/user-orders/${start}/${end}` , this.authToken());
  }

  getLoginTypeStatistics(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/login-per-type/${start}/${end}` , this.authToken());
  }

  getBonusPointStatistics(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/bonus-history/${start}/${end}` , this.authToken());
  }

  getDeleteReasonHistory(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/delete-reason/${start}/${end}` , this.authToken());
  }

  getReturningUsersStatistics(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/returning-users/${start}/${end}` , this.authToken());
  }

  getStatisticsCustomerNotSatisfied(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/user-not-satisfied/${start}/${end}` , this.authToken());
  }

  getStatisticsOldNewUsers(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/user-old-new/${start}/${end}` , this.authToken());
  }

  getStatisticsOldNewUsersRevenue(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/revenue-old-new/${start}/${end}` , this.authToken());
  }

  getStatisticsOldNewUsersTurnover(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/turnover-old-new/${start}/${end}` , this.authToken());
  }
  getStatisticsUsersPerGender(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/user-gender/${start}/${end}` , this.authToken());
  }

  getStatisticsUserPerCountry()  {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/statistics/user-country' , this.authToken());
  }

  getStatisticsAlbumsPerProduct()  {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/statistics/albums-per-product' , this.authToken());
  }

  getStatisticsUserPerDevice()  {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/statistics/users-per-device' , this.authToken());
  }

  getStatisticsOrderPerUser(start, end)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/orders-per-user/${start}/${end}` , this.authToken());
  }

  getStatisticsUsersPerFcm(start, end)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/users-per-device/${start}/${end}` , this.authToken());
  }

  getNotificationsPerStatus(start, end)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/notifications-per-status/${start}/${end}` , this.authToken());
  }

  getAlbumsStatistics(start, end)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/products/${start}/${end}` , this.authToken());
  }

  getHourlyStatistic(start, end)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/per-hour/${start}/${end}` , this.authToken());
  }

  getRpvStatisticsV2(start, end)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/rpv/${start}/${end}` , this.authToken());
  }

  getGeneralStatistics(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/general-statistics/${start}/${end}` , this.authToken());
  }

  getSalesStatistics(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/sales/${start}/${end}` , this.authToken());
  }

  getRegistrationSteps(start = null, end = null)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/registration-steps/${start}/${end}` , this.authToken());
  }

  getSessionStatistics(start = null, end = null, products)  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/statistics/session/${start}/${end}/${products}` , this.authToken());
  }

  getGeneralStatisticsAllTime()  {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/statistics/general-all-time' , this.authToken());

  }

  // USERS
  // dynamic function that gets users from database
  // if parameter was not provided it will get first 100 users sorted by id asc
  // param needs to be an object with {page, filter, sort} - everything is optional
  // function is smart and will get and set only relevant data from the parameter
  getUsers({page = 0, filter, sort}: { page?: number, filter?: Object, sort?: Object } = {page: 0}) {

    const data = JSON.stringify({
      sort: new SortData(sort),
      filter: new FilterData(filter)
    });

    return this.http.post(`${this.BASE_API_URL_V2}/api/admin/users/filter/${page}`, data, this.authToken());
  }

  getUsersAll({page = 0, filter, sort}: { page?: number, filter?: Object, sort?: Object } = {page: 0}) {

    const data = JSON.stringify({
      sort: new SortData(sort),
      filter: new FilterData(filter)
    });
    return this.http.post(`${this.BASE_API_URL_V2}/api/admin/users/all/filter`, data, this.authToken());
  }

  getUser(id) {
    return this.http.get(`${this.BASE_API_URL_V2}/api/admin/user/${id}`, this.authToken());
  }

  getUserSummary(id) {
    return this.http.get(`${this.BASE_API_URL_V2}/api/admin/user/details/${id}`, this.authToken());
  }

  getUserOrders(id) {
    return this.http.get(`${this.BASE_API_URL_V2}/api/admin/user/orders/${id}`, this.authToken());
  }

  updateUser(user) {
    return this.http.put(`${this.BASE_API_URL_V2}/api/admin/user`, user, this.authToken());
  }

  updateAdditional(user) {
    return this.http.put(`${this.BASE_API_URL_V2}/api/admin/user/additional`, user, this.authToken());
  }

  updateAddress(address) {
    return this.http.put(`${this.BASE_API_URL_V2}/api/admin/user/address`, address, this.authToken());
  }

  createAddress(address) {
    return this.http.post(`${this.BASE_API_URL_V2}/api/admin/user/address`, address, this.authToken());
  }

  changePassword(pass) {
    return this.http.post(`${this.BASE_API_URL_V2}/api/admin/user/password`, pass, this.authToken());
  }

  getAllUsers({page = 0, filter, sort}: { page?: number, filter?: Object, sort?: Object } = {page: 0}) {

    const data = JSON.stringify({
      sort: new SortData(sort),
      filter: new FilterData(filter)
    });
    return this.http.post(`${this.BASE_API_URL}/panel/user/all/filter/${page}`, data, this.authToken());
  }

  exportUsers({page = 0, filter, sort}: { page?: number, filter?: Object, sort?: Object } = {page: 0}) {
    const data = JSON.stringify({
      sort: new SortData(sort),
      filter: new FilterData(filter)
    });
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/users/export', data, this.authTokenBlob()).map((res) => {

    });
  }

  getRatingOptions() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/ratings/get', this.authToken());
  }

  getIssueOptions() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/issue/options', this.authToken());
  }

  refundPayment(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/transaction/refund/' + id, this.authToken());
  }

  sendNotifications(ids, title, desc, imageUrl, imagePath, filter) {
    const data = {ids, title, desc, imageUrl, imagePath, filter};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/notification/user/add', JSON.stringify(data), this.authToken());
  }

  getCombinations(filter) {
    return this.http.post(
      this.BASE_API_URL_V2 + '/api/admin/generateCartCombinations', JSON.stringify(filter), this.authTokenBlob()
    ).map((res) => {
      return new Blob([res], {type: 'application/pdf'});
    });
  }

  updateNotification(not) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/notification/description/update', JSON.stringify(not), this.authToken());
  }

  createIssue(issue: any) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/user/issue', issue, this.authToken());
  }

  updateIssue(issue: any) {
    return this.http.put(this.BASE_API_URL_V2 + '/api/admin/user/issue', issue, this.authToken());
  }

  toggleBlock(id: any) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/user/block/' + id, this.authToken());
  }

  getIssues(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/user/issues/' + id, this.authToken());
  }

  getUserSurveys(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/user/surveys/' + id, this.authToken());
  }

  filterIssues(filter, page) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/issues/filter/' + page , filter, this.authToken());
  }

  /*
  * Reports
  * */
  getMyReports() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/report/my', this.authToken());
  }

  toggleReport(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/report/toggle/' + id, this.authToken());
  }

  public uploadNotificationPhoto(file) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/notification/image/upload', file,
      this.authFileToken());
  }

  public getPanelProducts() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/panel/products',this.authToken());
  }

  public getPanelDeliveries() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/delivery/cost/all',this.authToken());
  }

  public changeDeliveryPrice(data) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/delivery/cost/change', JSON.stringify(data), this.authToken());
  }

  public changeProductPrices(data) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/order/change/product/price', JSON.stringify(data), this.authToken());
  }

  public changeProductActiveState(data) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/product/active/state/change', JSON.stringify(data), this.authToken());
  }

  public getFacture(values) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/create/word/export',
      JSON.stringify(values), this.authTokenBlob()).map((res) => {
      return new Blob([res], {type: 'application/docx'});
    });
  }

  public getFactureWithCountry(values, country) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/create/word/export',
      JSON.stringify(values), this.authTokenBlobWithCountry(country)).map((res) => {
      return new Blob([res], {type: 'application/docx'});
    });
  }

  public payAgainOrder(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/order/pay/again/' + id, this.authToken());
  }

  public getTrackings(orderId) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/tracking/get/' + orderId, this.authToken());
  }

  private authToken() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('adminYolo'));
    let country = localStorage.getItem('country');

    if (!country) {
      localStorage.setItem('country', 'rs');
      country = localStorage.getItem('country');
    }

    if (currentUser) {
      const headers = new HttpHeaders({
        'Authorization': currentUser.apiKey,
        'Content-Type': 'application/json',
        'Country': country,
        'Web': '1'
      });
      return {headers: headers};
    }
  }

  private authDateToken(date) {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('adminYolo'));
    let country = localStorage.getItem('country');

    if (!country) {
      localStorage.setItem('country', 'rs');
      country = localStorage.getItem('country');
    }

    if (currentUser) {
      let realDate = '';
      date ? realDate = date : date = '';
      const headers = new HttpHeaders({
        'Authorization': currentUser.apiKey,
        'Content-Type': 'application/json',
        'Country': country,
        'PickupDate': realDate
      });
      return {headers: headers};
    }
  }

  private authFileToken() {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('adminYolo'));
    let country = localStorage.getItem('country');

    if (!country) {
      localStorage.setItem('country', 'rs');
      country = localStorage.getItem('country');
    }

    if (currentUser) {
      const headers = new HttpHeaders({
        'Authorization': currentUser.apiKey,
        'Country': country,
      });
      return {headers: headers};
    }
  }

  private authTokenBlob(): any {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('adminYolo'));
    const country = localStorage.getItem('country');
    if (currentUser) {
      const headers = new HttpHeaders({
        'Authorization': currentUser.apiKey,
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
        'Country': country
      });

      return {headers: headers, responseType: "blob"};
    }

    return {}
  }

  private authTokenBlobWithCountry(country, ignoreCountry = 0): any {
    // create authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('adminYolo'));
    if (currentUser) {
      const headers = new HttpHeaders({
        'Authorization': currentUser.apiKey,
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
        'Country': country,
        'ignoreCountry': ignoreCountry.toString()
      });

      return {headers: headers, responseType: "blob"};
    }

    return {}
  }

  public addDevice(token) {
    const data = {token};
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin//device', JSON.stringify(data), this.authToken());
  }

  public getProductGroups() {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/product-group`, this.authToken());
  }

  public uploadFrontImage(id: number, file: any) {
    const endpoint = this.BASE_API_URL_V2 + `/api/admin/cover/front/${id}`;
    return this.http.post<DynamicLink>(endpoint, file, this.authFileToken());
  }

  public uploadCategoryImage(id: number, file: any) {
    const endpoint = this.BASE_API_URL_V2 + `/api/admin/cover-category/image/${id}`;
    return this.http.post<DynamicLink>(endpoint, file, this.authFileToken());
  }

  public uploadBackImage(id: number, file: any) {
    const endpoint = this.BASE_API_URL_V2 + `/api/admin/cover/back/${id}`;
    return this.http.post<DynamicLink>(endpoint, file, this.authFileToken());
  }

  public uploadCoverPages(type: string, id: number, file: any) {
    const endpoint = this.BASE_API_URL_V2 + `/api/admin/cover/page/${type}/${id}`;
    return this.http.post<DynamicLink>(endpoint, file, this.authFileToken());
  }

  /**
   *  Listing all Notifications
   */

  public changeStatusOfNotification(id: number) {
    const endPoint = this.BASE_API_URL_V2 + `/api/admin/order/notification/active/toggle/${id}`;
    return this.http.get<any>(endPoint, this.authToken());
  }

  marketingStatistics(date) {
    const data = {date};
    //todo check values
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/statistics/marketing', JSON.stringify(data), this.authToken());
  }

  // Upselling
  createUpsellingAction(upsellingAction: UpsellingAction) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/upselling', JSON.stringify(upsellingAction), this.authToken());
  }

  updateUpsellingAction(upsellingAction: UpsellingAction) {
    return this.http.put(this.BASE_API_URL_V2 + '/api/admin/upselling', JSON.stringify(upsellingAction), this.authToken());
  }

  deleteUpsellingAction(upsellingAction: UpsellingAction) {
    return this.http.delete(this.BASE_API_URL_V2 + '/api/admin/upselling/' + upsellingAction.id, this.authToken());
  }

  getUpsellingActions(page: number = 0) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/upsellings/' + page, this.authToken());
  }

  saveFilter(data) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/user/filter/save',  data, this.authToken());
  }

  getFilters() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/user/filter/all', this.authToken());
  }

  deleteFilter(id) {
    return this.http.delete(this.BASE_API_URL_V2 + '/api/admin/user/filter/delete/' + id, this.authToken());
  }

  saveAudience(data) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/user/audience/save',  data, this.authToken());
  }

  getAudiences() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/user/audience/all', this.authToken());
  }

  getAudiencesByIds(ids) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/user/audiences/' + ids, this.authToken());
  }

  createCampaign(campaign) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/campaign' , campaign,  this.authToken());
  }

  updateCampaign(campaign) {
    return this.http.put(this.BASE_API_URL_V2 + '/api/admin/campaign' , campaign,  this.authToken());
  }
  deleteCampaign(id) {
    return this.http.delete(this.BASE_API_URL_V2 + `/api/admin/campaign/${id}`,  this.authToken());
  }

  getCampaigns(text) {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/campaigns/${text}`,  this.authToken());
  }
  getEmailButtons() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/buttons/campaign', this.authToken());
  }

  getInfoBipBalance() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/infobip/balance', this.authToken());
  }

  createSurvey(survey) {
    return this.http.post(this.BASE_API_URL_V2 + '/api/admin/survey' , survey,  this.authToken());
  }

  updateSurvey(survey) {
    return this.http.put(this.BASE_API_URL_V2 + '/api/admin/survey' , survey,  this.authToken());
  }
  deleteSurvey(id) {
    return this.http.delete(this.BASE_API_URL_V2 + `/api/admin/survey/${id}`,  this.authToken());
  }

  getSurveys(text) {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/surveys/${text}`,  this.authToken());
  }

  deleteAudience(id) {
    return this.http.delete(this.BASE_API_URL_V2 + '/api/admin/user/audience/delete/' + id, this.authToken());
  }

  getInfoApproximateCost(mode, audience)
  {
    const country = localStorage.getItem('country');
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/approximate-cost/${mode}/${audience}/${country}` , this.authToken());
  }

  getInfoCampaignMoneySpent()
  {
    return this.http.get(this.BASE_API_URL_V2 + `/api/admin/infobip/money-spent` , this.authToken());
  }

  getUsersStat(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/statistics/users/' + from + '/' + to, this.authToken());
  }

  getCoversStat(from, to) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/statistics/cover/count/' + from + '/' + to, this.authToken());
  }

  getOrdersStat(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/statistics/order/count/' + from + '/' + to, this.authToken());
  }

  getProfitsStat(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/statistics/profits/' + from + '/' + to, this.authToken());
  }


  /**
   * Deprecated
   */
  getEvents() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/events/all', this.authToken());
  }

  getEmployee(id) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/employee/get/' + id, this.authToken());
  }
  getStructure() {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/structure', this.authToken());
  }

  getRequests(page) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/requests/' + page, this.authToken());
  }

  cancelAppointment(id) {
    const data = {'eventId': id};
    return this.http.post(this.BASE_API_URL_V2 + '/panel/appointment/cancel', JSON.stringify(data), this.authToken());
  }

  cancelDayOff(id) {
    const data = {'eventId': id};
    return this.http.post(this.BASE_API_URL_V2 + '/panel/dayOff/cancel', JSON.stringify(data), this.authToken());
  }

  getAdminSeminars(id, page) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/seminars/' + id + '/' + page, this.authToken());
  }

  getInfo() {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/statistics/info', this.authToken());
  }

  getDevicesStat(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/devices/' + from + '/' + to, this.authToken());
  }

  getDevicePercentageStat(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/device/percentage/' + from + '/' + to, this.authToken());
  }

  getNewOldUsersDays(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/users/new/old/days/' + from + '/' + to, this.authToken());
  }

  getRpvStatistics(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/statistics/rpv/' + from + '/' + to, this.authToken());
  }

  getOrdersPercent(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/orders/percent/' + from + '/' + to, this.authToken());
  }

  getReturningUsers(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/users/returning/' + from + '/' + to, this.authToken());
  }

  getNewOldUsersHours(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/users/new/old/hours/' + from + '/' + to, this.authToken());
  }

  getUsersAllPercentage(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/all/users/country/' + from + '/' + to, this.authToken());
  }

  getOrdersEuPercentage(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/eu/orders/country/' + from + '/' + to, this.authToken());
  }

  getUsersEuPercentage(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/eu/users/country/' + from + '/' + to, this.authToken());
  }

  getUserOrdersStat(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/users_orders/' + from + '/' + to, this.authToken());
  }

  getLoginsPerDay(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/logins/day/' + from + '/' + to, this.authToken());
  }

  getLoginsPerHour(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/logins/hour/' + from + '/' + to, this.authToken());
  }





  getSentProfitsStat(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/api/admin/statistics/sent/profits/' + from + '/' + to, this.authToken());
  }

  getOrdersAllPercentage(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/all/orders/country/' + from + '/' + to, this.authToken());
  }
  getNewOldOrdersDays(from: string, to: string) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/stat/orders/new/old/days/' + from + '/' + to, this.authToken());
  }

  public getSurvey(date) {
    return this.http.post<Survey>(this.BASE_API_URL_V2 + `/all/answers/from/date`, date, this.authToken());
  }

  public getSurveyForAllDays(surveyForAllDays) {
    return this.http.post<Survey>(this.BASE_API_URL_V2 + `/all/answers/for/everyDay/in/date`, surveyForAllDays, this.authToken());
  }

  //APPOINTMENTS
  getEmployeesForAppointments() {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/appointment/employees', this.authToken());
  }

  addAppointment(date, time, adminId, description) {
    const data = {date, time, adminId, description};
    return this.http.post(this.BASE_API_URL_V2 + '/panel/appointment/add', JSON.stringify(data), this.authToken());
  }

  sendInfoMail(users, title, body) {
    const data = {users, title, body};
    return this.http.post(this.BASE_API_URL_V2 + '/infoMail', JSON.stringify(data), this.authToken());
  }

  addVacation(startDate, endDate, description) {
    const data = {startDate, endDate, description};
    return this.http.post(this.BASE_API_URL_V2 + '/panel/dayOff/add', JSON.stringify(data), this.authToken());
  }

  confirmDayOff(eventId, confirm) {
    const data = {eventId, confirm};
    return this.http.post(this.BASE_API_URL_V2 + '/panel/dayOff/confirm', JSON.stringify(data), this.authToken());
  }

  setGiftOrder(orderId, isFast) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/order/gift/' + orderId + '/' + isFast, this.authToken());
  }

  setFastOrder(orderId, isFast) {
    return this.http.get(this.BASE_API_URL_V2 + '/panel/order/fast/' + orderId + '/' + isFast, this.authToken());
  }

  public changeActivityTrueProduct(product) {
    return this.http.post(this.BASE_API_URL_V2 + '/panel/change/product/activity/true/' +
      product, JSON.stringify(product), this.authToken());
  }

  public changeActivityFalseProduct(product) {
    return this.http.post(this.BASE_API_URL_V2 + '/panel/change/product/activity/false/' +
      product, JSON.stringify(product), this.authToken());
  }
}
