import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Colors} from "../../Colors";
import {ApiService} from "../../api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-campaign-form',
    templateUrl: './campaign-form.component.html',
    styleUrls: ['./campaign-form.component.css']
})
export class CampaignFormComponent implements OnInit {
    @Input() mode;
    @Input() buttons;
    @Input() audienceIds;
    @Input() isNew;
    @Input() campaign;
    @Input() width;
    @Input() isPopup;
    @Input() audiences;

    @Output() campaignChange = new EventEmitter<any>();


    title: '';
    modes = [
        {
            id: 1, name: 'email'
        },
        {
            id: 2, name: 'notifications'
        },
        {
            id: 3, name: 'viber'
        },];
    totalCount = 0;
    name = '';
    text = '';
    startDate = null;
    startTime = '';
    image = null;
    imagePath = null;
    colors = Colors;
    buttonText = '';
    buttonUrl = '';
    buttonOption = 1;
    audienceMode = false;
    country = null;

    constructor(private service: ApiService, private snackBar: MatSnackBar, private translate: TranslateService) {
    }

    ngOnInit() {
        if (this.campaign) {
            this.name = this.campaign.name;
            this.title = this.campaign.title;
            this.text = this.campaign.text;
            this.imagePath = this.campaign.image;
            this.image = this.campaign.imageUrl;
            this.buttonText = this.campaign.button_text;
            this.buttonUrl = this.campaign.button_url;
            this.buttonOption = this.campaign.button_option;
            const startArr = this.campaign.start.split(" ");
            this.startDate = startArr[0];
            this.startTime = startArr[1];
            this.country = this.campaign.country_id
        }
        else
        {
          this.country = localStorage.getItem("country");
        }
    }

    public onSelectFile(files: FileList) {
        const fileToUpload = files.item(0);
        const formData = new FormData();
        formData.append('image', fileToUpload);
        this.service.uploadNotificationPhoto(formData).subscribe(
            (data: any) => {
                this.imagePath = data.path;
                this.image = data.url;
            });
    }

    saveCampaign()
    {
        const start= this.getStart();
        let audiences = this.audienceIds;

        if (typeof this.audienceIds != 'string')
        {
          audiences = audiences.join(",");
        }

        const campaign = {
            name: this.name,
            text: this.text,
            start: start,
            image: this.imagePath,
            type: this.mode,
            audienceIds: audiences,
            buttonText: this.buttonText,
            buttonUrl: this.buttonUrl,
            title: this.title,
            buttonOption: this.buttonOption,
            country: this.country,
            id: 0
        };

        if (this.isNew == 1)
        {
            this.service.createCampaign(campaign).subscribe((data) => {
                this.snackBar.open(this.translate.instant('saved'), "OK", {duration: 3000});
                this.campaignChange.emit(data);
            }, (err) => {
                if (err.status == 470)
                {
                    this.snackBar.open(err.error, "OK", {duration: 3000});
                }
                else
                {
                    this.snackBar.open(this.translate.instant('thereWasAnError'), "OK", {duration: 3000});
                }
            })

        }
        else
        {
          campaign.id = this.campaign.id;
            this.service.updateCampaign(campaign).subscribe((data) => {
                this.snackBar.open(this.translate.instant('saved'), "OK", {duration: 3000});
                this.campaignChange.emit(data);
            }, (err) => {
                if (err.status == 470) {
                    this.snackBar.open(err.error, "OK", {duration: 3000});
                } else {
                    this.snackBar.open(this.translate.instant('thereWasAnError'), "OK", {duration: 3000});

                }
            })
        }

    }

    changeTime(event) {
        this.startTime = event;
    }

    getStart() {
        const date = new Date(this.startDate);
        const [hours, minutes] = this.startTime.split(':');
        date.setHours(Number(hours));
        date.setMinutes(Number(minutes));

        return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes();
    }

    getAudienceArray() {
        if (this.audienceIds) {
            const fineAudiences = this.audiences.filter((a) => this.audienceIds.includes(a.id));
            return fineAudiences;
        } else {
            return [];
        }
    }

    getNotSelectedudiences() {
        if (this.audienceIds) {
            const fineAudiences = this.audiences.filter((a) => !this.audienceIds.includes(a.id));
            return fineAudiences;
        } else {
            return [];
        }
    }

    selectedAudience(audience: any) {
      this.audienceIds.push(audience.id);
      this.audienceMode = false;
    }

    removeAudience(id) {
        console.log(this.audienceIds, id);
        const index = this.audienceIds.findIndex(a => a == id);
        if (index != -1) {
            this.audienceIds.splice(index, 1);
            this.audienceIds = [...this.audienceIds];
        }
    }


}
