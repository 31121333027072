import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './main/main.component';
import {LoginComponent} from './login/login.component';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DatePipe} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PromoComponent} from './promo/promo.component';
import {OrdersListComponent} from './orders-list/orders-list.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {CostsComponent} from './costs/costs.component';
import {LOCALE_ID} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeSr from '@angular/common/locales/sr-Latn';
import {DataService} from "./data-service.service";
import {ProfileComponent} from './profile/profile.component';
import {HttpClient, HttpClientModule, HttpHandler, HTTP_INTERCEPTORS} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {MessagingService} from "./shared/messaging.service";
import {AngularFireDatabase, AngularFireDatabaseModule} from "@angular/fire/database";
import {AngularFireModule} from "@angular/fire";
import {environment} from "../environments/environment";
import {AngularFireAuth, AngularFireAuthModule} from "@angular/fire/auth";
import {ApiService} from "./api.service";
import {AngularFireMessagingModule} from "@angular/fire/messaging";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {AboutComponent} from "./about/about.component";
import {ProductsComponent} from "./products/products.component";
import {ProductdialogComponent} from "./productdialog/productdialog.component";
import {StickyHeaderComponent} from "./sticky-header/sticky-header.component";
import {EmployeesComponent} from "./employees/employees.component";
import {SafePipe, SectorsComponent} from "./sectors/sectors.component";
import {AppointmentComponent} from "./appointment/appointment.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {VacationComponent} from "./vacation/vacation.component";
import {FullCalendarModule} from "@fullcalendar/angular";
import {CalendarComponent} from "./calendar/calendar.component";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {EventSpecificsComponent} from './event-specifics/event-specifics.component';
import {NotificationComponent} from './notification/notification.component';
import { AuthInterceptor } from 'auth.interceptor'; // Replace with actual path


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

import {InvoicesComponent} from './invoices/invoices.component';
import {CustomsComponent} from './customs/customs.component';
import {DownloadByIdComponent} from './download-by-id/download-by-id.component';
import {UsersComponent} from './users/users.component';
import {UserComponent} from './users/user/user.component';
import {DMYPipe} from './utils';
import {ErrorDialogComponent} from './dialogs/error-dialog-component';
import {PrintOrdersComponent} from "./print-orders/print-orders.component";
import {NoteComponent} from "./note/note.component";
import {ConfirmComponent} from "./confirm/confirm.component";
import {PrintComponent} from "./print/print.component";
import {StatComponent} from "./stat/stat.component";
import {InfoComponent} from "./info/info.component";
import {ChartsModule} from "ng2-charts";
import {MaterialModule} from "./material.module";
import {EmployeeComponent} from './employee/employee.component';
import {StrukturaComponent} from './struktura/struktura.component';
import {GoogleChartsModule} from "angular-google-charts";
import {PermissionsComponent} from './permissions/permissions.component';
import {SuppliesComponent} from './supplies/supplies.component';
import {SupplyHistoryComponent} from './supply-history/supply-history.component';
import {CoversComponent} from './covers/covers.component';
import {FullImageComponent} from './full-image/full-image.component';
import {CoverHistoryComponent} from './cover-history/cover-history.component';
import {InViewportModule} from "@thisissoon/angular-inviewport";
import {MachinesComponent} from './machines/machines.component';
import {MachineRepairComponent} from './machine-repair/machine-repair.component';
import {MachineInfoComponent} from './machine-info/machine-info.component';
import {MachineDeleteComponent} from './machine-delete/machine-delete.component';
import {AllRequestsComponent} from './all-requests/all-requests.component';
import {NotificationModalComponent} from './notification-modal/notification-modal.component';
import {DeleteEmployeeComponent} from './delete-employee/delete-employee.component';
import {BonusesComponent} from './bonuses/bonuses.component';
import {MergeOrdersComponent} from './merge-orders/merge-orders.component';
import {EmailModalComponent} from './email-modal/email-modal.component';
import {RefundDialogComponent} from './refund-dialog/refund-dialog.component';
import {ClickOutsideDirective} from './click-outside.directive';
import {DownloadById6Component} from './download-by-id6/download-by-id6.component';
import {SendNotificationComponent} from './send-notification/send-notification.component';
import {UploadBannerComponent} from "./upload-banner/upload-banner.component";
import {GetBannersComponent} from './get-banners/get-banners.component';
import {ProductAlbumsComponent} from './product-albums/product-albums.component';
import {FactureComponent} from './facture/facture.component';
import {SurveyComponent} from './survey/survey.component';
import {DynamicLinksComponent} from './dynamic-links/dynamic-links.component';
import {ConfirmModalComponent} from './confirm-modal/confirm-modal.component';
import {TemporalPriceComponent} from './temporal-price/temporal-price.component';
import {TemporalPriceModalComponent} from './temporali-price-modal/temporal-price-modal.component';
import {CoverAddNewComponent} from './cover-add-new/cover-add-new.component';
import {ListingNotificationComponent} from './listing-notification/listing-notification.component';
import {PricesComponent} from './prices/prices.component';
import {ConfirmPriceChangeDialogComponent} from './confirm-price-change-dialog/confirm-price-change-dialog.component';
import {GalleryComponent} from './gallery/gallery.component';
import {DetailImageComponent} from './detail-image/detail-image.component';
import {MarketingStatisticComponent} from './marketing-statistic/marketing-statistic.component';
import {OrderHistoryComponent} from './order-history/order-history.component';
import {RefundPopupComponent} from './refund-popup/refund-popup.component';
import {ButtonComponent} from "./components/button/button.component";
import {LoadingComponent} from './loading/loading.component';
import {CoverCategoryAddNewComponent} from './cover-category-add-new/cover-category-add-new.component';
import {RatingCommentPopUpComponent} from './rating-comment-pop-up/rating-comment-pop-up.component';
import {PriceCombinedComponent} from './price-combined/price-combined.component';
import {UpsellingComponent} from './upselling/upselling.component';
import {UpsellingFormComponent} from './upselling/upselling-form/upselling-form.component';
import {UpsellingModalComponent} from './upselling/upselling-modal/upselling-modal.component';
import {MatNativeDateModule, MatOptionModule} from "@angular/material";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {AddressModalComponent} from './users/user/address-modal/address-modal.component';
import {SvgIconComponent} from './users/user/svg-icon/svg-icon.component';
import {ChangePassModalComponent} from './users/user/change-pass-modal/change-pass-modal.component';
import {NewIssueComponent} from './users/user/new-issue/new-issue.component';
import {MoreTextComponent} from './users/user/more-text/more-text.component';
import { IssuesComponent } from './issues/issues.component';
import { CampaignComponent } from './users/campaign/campaign.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CampaignsComponent } from './users/campaigns/campaigns.component';
import { CampaignPopupComponent } from './users/campaign-popup/campaign-popup.component';
import { CampaignFormComponent } from './users/campaign-form/campaign-form.component';
import { SurveyFormComponent } from './survey/survey-form/survey-form.component';
import { SurveyPopupComponent } from './survey/survey-popup/survey-popup.component';
import { SurveysComponent } from './survey/surveys/surveys.component';
import { NewSurveyComponent } from './survey/new-survey/new-survey.component';
import { NewStatisticComponent } from './new-statistic/new-statistic.component';
import { UserPerStatusComponent } from './new-statistic/charts/user-per-status/user-per-status.component';
import { UserPerCountryComponent } from './new-statistic/charts/user-per-country/user-per-country.component';
import { DotsLoaderComponent } from './dots-loader/dots-loader.component';
import { AlbumsPerProductComponent } from './new-statistic/charts/albums-per-product/albums-per-product.component';
import { UsersPerDeviceComponent } from './new-statistic/charts/users-per-device/users-per-device.component';
import { AllTimeStatisticsComponent } from './new-statistic/all-time-statistics/all-time-statistics.component';
import { CustomerStatisticsComponent } from './new-statistic/customer-statistics/customer-statistics.component';
import { CustomerSatisfactionComponent } from './new-statistic/charts/customer-satisfaction/customer-satisfaction.component';
import { CustomerNotSatisfiedComponent } from './new-statistic/charts/customer-not-satisfied/customer-not-satisfied.component';
import { NewOldUsersComponent } from './new-statistic/charts/new-old-users/new-old-users.component';
import { NewOldUsersRevenueComponent } from './new-statistic/charts/new-old-users-revenue/new-old-users-revenue.component';
import { NewOldUsersTurnoverComponent } from './new-statistic/charts/new-old-users-turnover/new-old-users-turnover.component';
import { UsersPerGenderComponent } from './new-statistic/charts/users-per-gender/users-per-gender.component';
import { OrdersPerUserComponent } from './new-statistic/charts/orders-per-user/orders-per-user.component';
import { UsersPerFcmComponent } from './new-statistic/charts/users-per-fcm/users-per-fcm.component';
import { NotificationsPerStatusComponent } from './new-statistic/charts/notifications-per-status/notifications-per-status.component';
import { AlbumsComponent } from './new-statistic/charts/albums/albums.component';
import { HourlyComponent } from './new-statistic/charts/hourly/hourly.component';
import { RpvComponent } from './new-statistic/charts/rpv/rpv.component';
import { UserOrdersComponent } from './new-statistic/charts/user-orders/user-orders.component';
import { LoginTypeComponent } from './new-statistic/charts/login-type/login-type.component';
import { ReturningUsersComponent } from './new-statistic/charts/returning-users/returning-users.component';
import { CustomerJourneyComponent } from './new-statistic/customer-journey/customer-journey.component';
import { RegistrationStepsComponent } from './new-statistic/charts/registration-steps/registration-steps.component';
import { BonusPointsComponent } from './new-statistic/charts/bonus-points/bonus-points.component';
import { DeleteReasonsComponent } from './new-statistic/charts/delete-reasons/delete-reasons.component';
import { SessionComponent } from './new-statistic/charts/session/session.component';
import { SalesStatisticsComponent } from './new-statistic/sales-statistics/sales-statistics.component';
import { ProfitComponent } from './new-statistic/charts/profit/profit.component';
import {CampaignStatisticsComponent} from "./new-statistic/campaigns/campaign-statistics.component";


registerLocaleData(localeSr, 'sr-Latn');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    NoteComponent,
    ConfirmComponent,
    InfoComponent,
    PrintComponent,
    StatComponent,
    PromoComponent,
    OrdersListComponent,
    CostsComponent,
    InvoicesComponent,
    CustomsComponent,
    DownloadByIdComponent,
    ProfileComponent,
    AboutComponent,
    ProductsComponent,
    ProductdialogComponent,
    PrintOrdersComponent,
    StickyHeaderComponent,
    EmployeesComponent,
    SectorsComponent,
    SafePipe,
    AppointmentComponent,
    VacationComponent,
    CalendarComponent,
    EventSpecificsComponent,
    NotificationComponent,
    UsersComponent,
    DMYPipe,
    ErrorDialogComponent,
    UserComponent,
    PrintOrdersComponent,
    EmployeeComponent,
    StrukturaComponent,
    PermissionsComponent,
    SuppliesComponent,
    SupplyHistoryComponent,
    CoversComponent,
    FullImageComponent,
    CoverHistoryComponent,
    MachinesComponent,
    MachineRepairComponent,
    MachineInfoComponent,
    MachineDeleteComponent,
    AllRequestsComponent,
    NotificationModalComponent,
    DeleteEmployeeComponent,
    BonusesComponent,
    MergeOrdersComponent,
    EmailModalComponent,
    RefundDialogComponent,
    ClickOutsideDirective,
    DownloadById6Component,
    SendNotificationComponent,
    UploadBannerComponent,
    GetBannersComponent,
    ProductAlbumsComponent,
    FactureComponent,
    SurveyComponent,
    DynamicLinksComponent,
    ConfirmModalComponent,
    TemporalPriceComponent,
    TemporalPriceModalComponent,
    CoverAddNewComponent,
    ListingNotificationComponent,
    PricesComponent,
    ConfirmPriceChangeDialogComponent,
    GalleryComponent,
    DetailImageComponent,
    MarketingStatisticComponent,
    OrderHistoryComponent,
    RefundPopupComponent,
    ButtonComponent,
    ButtonComponent,
    LoadingComponent,
    CoverCategoryAddNewComponent,
    RatingCommentPopUpComponent,
    PriceCombinedComponent,
    UpsellingComponent,
    UpsellingFormComponent,
    UpsellingModalComponent,
    AddressModalComponent,
    SvgIconComponent,
    ChangePassModalComponent,
    NewIssueComponent,
    MoreTextComponent,
    IssuesComponent,
    CampaignComponent,
    CampaignsComponent,
    CampaignPopupComponent,
    CampaignFormComponent,
    SurveyFormComponent,
    SurveyPopupComponent,
    SurveysComponent,
    NewSurveyComponent,
    NewStatisticComponent,
    UserPerStatusComponent,
    UserPerCountryComponent,
    DotsLoaderComponent,
    AlbumsPerProductComponent,
    UsersPerDeviceComponent,
    AllTimeStatisticsComponent,
    CustomerStatisticsComponent,
    CustomerSatisfactionComponent,
    CustomerNotSatisfiedComponent,
    NewOldUsersComponent,
    NewOldUsersRevenueComponent,
    NewOldUsersTurnoverComponent,
    UsersPerGenderComponent,
    OrdersPerUserComponent,
    UsersPerFcmComponent,
    NotificationsPerStatusComponent,
    AlbumsComponent,
    HourlyComponent,
    RpvComponent,
    UserOrdersComponent,
    LoginTypeComponent,
    ReturningUsersComponent,
    CustomerJourneyComponent,
    RegistrationStepsComponent,
    BonusPointsComponent,
    DeleteReasonsComponent,
    SessionComponent,
    SalesStatisticsComponent,
    ProfitComponent,
    CampaignStatisticsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MatCardModule, MatButtonModule, MatInputModule, MatSnackBarModule, MatOptionModule, MatSelectModule, MatToolbarModule,
    MatIconModule, MatSidenavModule, MatListModule, MatChipsModule, MatSlideToggleModule, MatDialogModule, MatCheckboxModule,
    MatMenuModule, MatProgressSpinnerModule, MatTabsModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule, InfiniteScrollModule, NgbModule,
    FullCalendarModule, NgxMaterialTimepickerModule,
    MaterialModule, GoogleChartsModule, MatButtonToggleModule, InViewportModule, MatProgressBarModule, NgxSliderModule, AngularEditorModule
  ],
    entryComponents: [
        NoteComponent,
        ConfirmComponent,
        InfoComponent,
        CustomsComponent,
        DownloadByIdComponent,
        ProductdialogComponent,
        AppointmentComponent,
        VacationComponent,
        ErrorDialogComponent,
        EventSpecificsComponent,
        EmployeeComponent,
        SupplyHistoryComponent,
        FullImageComponent,
        CoverHistoryComponent,
        MachineRepairComponent,
        MachineInfoComponent,
        MachineDeleteComponent,
        AllRequestsComponent,
        NotificationModalComponent,
        DeleteEmployeeComponent,
        MergeOrdersComponent,
        EmailModalComponent,
        RefundDialogComponent,
        DownloadById6Component,
        SendNotificationComponent,
        UploadBannerComponent,
        FactureComponent,
        ConfirmModalComponent,
        TemporalPriceModalComponent,
        CoverAddNewComponent,
        ConfirmPriceChangeDialogComponent,
        GalleryComponent,
        DetailImageComponent,
        OrderHistoryComponent,
        RefundPopupComponent,
        CoverCategoryAddNewComponent,
        RatingCommentPopUpComponent,
        UpsellingModalComponent,
        AddressModalComponent,
        ChangePassModalComponent,
        NewIssueComponent,
        MoreTextComponent,
        CampaignPopupComponent,
        SurveyPopupComponent
    ],
    providers: [
      DatePipe,
      {
        provide: LOCALE_ID,
        useValue: 'sr-Latn'
      },
      DataService,
      HttpClient,
      MessagingService,
      ApiService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
