import {Component, OnInit} from '@angular/core';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {DataService} from "./data-service.service";
import {ThemeService} from "./theme.service";
import {main} from "./theme/theme";
import {environment} from "../environments/environment";

declare global {
  interface Window {
    Pusher: any;
  }
}

window.Pusher = Pusher;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  currentUser = JSON.parse(localStorage.getItem('adminYolo'));
  apiKey = this.currentUser ? this.currentUser.apiKey : "";
  message: any;
  echo: any;

  echoOptions = {
    broadcaster: "pusher",
    key: environment.socketKey,
    authEndpoint: environment.socketAuth,
    wsHost:  environment.socketHost,
    wssHost: environment.socketHost,
    auth: {
      headers: {
        accept: "application/json",
        Authorization: this.apiKey
      },
    },
    wsPort: environment.socketPort,
    wssPort: environment.socketPort,
    disableStats: true,
    encrypted: false,
    forceTLS: false,
    enabledTransports: ["ws", "wss"],
    transports: ["websocket"],
    cluster: environment.socketCluster,
  }

  constructor(private dataService: DataService, private themeService: ThemeService) {
    this.themeService.setActiveTheme(main);
  }

  ngOnInit() {
    // try {
    //   // Initialize Echo
    //   this.echo = new Echo(this.echoOptions);
    //
    //   this.echo.connector.pusher.connection.bind('connected', () => {
    //     const socketId = this.echo.socketId();
    //     console.log('Socket connection established with ID: ' + socketId);
    //
    //     this.echo.channel('private-admin_panel')
    //       .listen('.order_update', (message: string) => {
    //         console.log('Order update Broadcast received! ', message);
    //         this.dataService.updateOrder(message);
    //       })
    //       .listen('.download_complete', (data: any) => {
    //         console.log('Download prepared! ', data);
    //         this.dataService.sendNotification(data);
    //       })
    //       .listen('.test-1', (message: string) => {
    //         console.log('Broadcast received from channel (test-1)! ', message);
    //       });
    //   });
    //
    // } catch (err) {
    //   console.log(err);
    // }
  }
}
